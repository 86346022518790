import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEnv, getEnvs, updateEnv } from '../api/envsApi';
import { showAlert } from '../slices/alertsSlice';
import { setCurrentEnv, setEnvsList } from '../slices/envsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const getEnvsList = createAsyncThunk('envs/getEnvsList', (_, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getEnvs(token)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setEnvsList({ envs: data.envs })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCurrentEnv = createAsyncThunk('envs/getCurrentEnv', ({ id, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getEnv(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentEnv({ env: data.env })))
  .then(() => callback?.())
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const updateCurrentEnv = createAsyncThunk('envs/updateCurrentEnv', ({ id, env }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateEnv(token, id, env)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentEnv({ env: data.env })))
  .then(() => dispatch(showAlert({ message: 'Configuración actualizada', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
