import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Grid, MenuItem, TextField, Typography } from '@mui/material';

import { updateCurrentProspect } from '../../../../actions/prospectsActions';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
  title: {
    py: '1rem'
  },
  companyWrapper: {
    display: 'flex',
    alignItems: 'center'
  }
}

const ProspectDetailsForm = () => {
  const [prospect, setProspect] = useState({
    _id: '',
    name: '',
    nit: '',
    address: '',
    city: '',
    taxType: '',
    email: '',
    phoneNumber: '',
    contactName: '',
    contactPhoneNumber: '',
    contactEmail: '',
  });

  const dispatch = useDispatch();
  const current = useSelector(state => state.prospects.current);
    
  useEffect(() => setProspect({ ...prospect, ...current }), [current]);

  const handleChange = e => {
    setProspect({ ...prospect, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();

    const { name, address, city, phoneNumber, email, taxType, nit, contactName, contactPhoneNumber, contactEmail } = prospect;

    dispatch(updateCurrentProspect({
      id: prospect._id,
      prospect: { name, address, city, phoneNumber, email, taxType, nit, contactName, contactPhoneNumber, contactEmail }
    }));
  }

  return (
    <Card sx={styles.card}>
      <CardHeader title="Datos del prospecto" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Nombre" name="name" value={prospect.name} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Ciudad" name="city" value={prospect.city} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={12} sx={styles.inputWrapper}>
            <TextField label="Dirección" name="address" value={prospect.address} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Correo electrónico" name="email" value={prospect.email} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Teléfono móvil" name="phoneNumber" value={prospect.phoneNumber} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="NIT" name="nit" value={prospect.nit} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField select label="Tipo de contribuyente" name="taxType" value={prospect.taxType} onChange={handleChange}>
              <MenuItem value="Persona Natural">Persona Natural</MenuItem>
              <MenuItem value="Persona Jurídica">Persona Jurídica</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sx={styles.title}>
            <Typography variant="h6">Contacto</Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Nombre de contacto" name="contactName" value={prospect.contactName} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Correo de contacto" name="contactEmail" value={prospect.contactEmail} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Teléfono de contacto" name="contactEmail" value={prospect.contactEmail} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button color="primary" onClick={handleSubmit}>Guardar</Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default ProspectDetailsForm;
