import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AttachFile } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import { uploadPrivacyFile } from '../../../actions/docsActions';

const styles = {
  container: {
    display: 'block',
    textAlign: 'center'
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  column: {
    px: '1rem'
  },
  submitButton: {
    mt: '2rem'
  }
}

const PrivacyPolicyUpload = props => {
  const [file, setFiles] = useState(null);

  const dispatch = useDispatch();

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  const handleFileChange = e => {
    setFiles(e.target.files[0]);
  }

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(uploadPrivacyFile({ file, callback: props.callback }))
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Actualizar Política de Privacidad</DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.container}>
          <Grid container>
            <Grid item xs={12} sx={styles.column}>
              <Typography>Seleccione archivo</Typography>
              <Button variant="outlined" color="primary" component="label" size="large" sx={styles.iconButton}>
                <input hidden accept="application/pdf" type="file" onChange={handleFileChange} />
                <AttachFile />
              </Button>
              <Typography variant="caption" fontWeight="bold" display="block">{file?.name ?? 'Sin seleccionar'}</Typography> 
            </Grid>
          </Grid>
          <Button onClick={handleSubmit} disabled={!file} sx={styles.submitButton}>Subir</Button>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button variant="text" onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default PrivacyPolicyUpload;
