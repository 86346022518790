import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current: {},
  list: [],
  meta: {}
}

const prospectsSlice = createSlice({
  name: 'prospects',
  initialState,
  reducers: {
    setProspectsList: (state, { payload }) => ({ ...state, list: [...payload.prospects] }),
    setCurrentProspect: (state, { payload }) => ({ ...state, current: { ...payload.prospect } }),
    setProspectsMeta: (state, { payload }) => ({ ...state, meta: { ...payload.prospects } }),
    resetProspectsState: () => ({ list: [], current: { }, meta: { } })
  }
});

export const { setProspectsList, setCurrentProspect, setProspectsMeta, resetProspectsState } = prospectsSlice.actions;
export default prospectsSlice.reducer;
