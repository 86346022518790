import { useState } from 'react';
import { TableRow, TableCell, Link as StyledLink } from '@mui/material';
import { Link } from 'react-router-dom';
import EmployeeDetailsRepotsDialog from './EmployeeDetailsRepotsDialog';
import EmployeeDetailsEmploymentEdit from './EmployeeDetailsEmploymentEdit';
import shortDateFormatter from '../../../../formatters/shortDateFormatter';

const styles = {
  link: {
    cursor: 'pointer'
  }
}

const EmploymentsListItem = props => {
  const [openReports, setOpenReports] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const handleOpenReports = () => setOpenReports(true);
  const handleCloseReports = () => setOpenReports(false);

  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  return (
    <>
      <TableRow>
        <TableCell><Link to={`/dashboard/empresas/${props.companyRef?._id}`}>{props.companyRef?.name}</Link></TableCell>
        <TableCell>{props.current ? 'Sí' : 'No'}</TableCell>
        <TableCell>{props.admissionDate ? shortDateFormatter(props.admissionDate) : 'Sin fecha'}</TableCell>
        <TableCell>{!props.current ? props.departureDate ? shortDateFormatter(props.departureDate) : 'Sin fecha' : 'Empleo actual'}</TableCell>
        <TableCell>{props.departureReason}</TableCell>
        <TableCell>{props.firstCharge}</TableCell>
        <TableCell>{props.lastCharge}</TableCell>
        <TableCell>{props.jobArea}</TableCell>
        <TableCell>{props.supervisorName}</TableCell>
        <TableCell>{props.supervisorPhone}</TableCell>
        <TableCell>{props.period}</TableCell>
        <TableCell>{props.pin}</TableCell>
        <TableCell><StyledLink sx={styles.link} onClick={handleOpenReports}>Click aquí</StyledLink></TableCell>
        <TableCell><StyledLink sx={styles.link} onClick={handleOpenEdit}>Editar aquí</StyledLink></TableCell>
      </TableRow>
      <EmployeeDetailsRepotsDialog open={openReports} onClose={handleCloseReports} employmentId={props._id} />
      <EmployeeDetailsEmploymentEdit open={openEdit} onClose={handleCloseEdit} {...props} />
    </>
  );
}

export default EmploymentsListItem;
