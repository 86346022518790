import { createAsyncThunk } from '@reduxjs/toolkit';
import { createCompany, getCompany, getCompanies, updateCompany, getCompanyStatistics, processCompanyEmployeesBatch, processCompanyEmployeesBatchImages, processCompanyEmployeesBatchNotices, getCompaniesMetadata, getCompaniesCsv } from '../api/companiesApi';
import { showAlert } from '../slices/alertsSlice';
import { setCurrentCompany, setCompaniesList, setCurrentCompanyStatistics, setCompaniesMeta } from '../slices/companiesSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';
import { downloadFile } from '../utils/donwloadFile';

export const getCompaniesList = createAsyncThunk('companies/getCompaniesList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getCompanies(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCompaniesList({ companies: data.companies })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});


export const downloadCompaniesCsv = createAsyncThunk('employees/downloadCompaniesCsv', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getCompaniesCsv(token, query)
  .then(response => response.blob())
  .then(blob => downloadFile(blob, 'empresas.csv'))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCompaniesMeta = createAsyncThunk('companies/getCompaniesMeta', (_, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getCompaniesMetadata(token)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCompaniesMeta({ companies: data.companies })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});


export const getCurrentCompany = createAsyncThunk('companies/getCurrentCompany', ({ id }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getCompany(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentCompany({ company: data.company })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const createNewCompany = createAsyncThunk('companies/createNewCompany', ({ company, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createCompany(token, company)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.company))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const updateCurrentCompany = createAsyncThunk('companies/updateCurrentCompany', ({ id, company }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateCompany(token, id, company)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentCompany({ company: data.company })))
  .then(() => dispatch(showAlert({ message: 'Empresa actualizada', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCurrentCompanyStatistics = createAsyncThunk('companies/getCurrentCompanyStatistics', ({ id }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getCompanyStatistics(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentCompanyStatistics({ statistics: data.employees })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const processCompanyEmployeesData = createAsyncThunk('companies/processCompanyEmployeesData', ({ id, files, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  processCompanyEmployeesBatch(token, id, files.csv)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => processCompanyEmployeesBatchImages(token, id, files.images))
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => processCompanyEmployeesBatchNotices(token, id, files.notices))
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => callback())
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const processCompanyEmployeesCsv = createAsyncThunk('companies/processCompanyEmployeesCsv', ({ id, csv }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  processCompanyEmployeesBatch(token, id, csv)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => dispatch(showAlert({ message: 'CSV Procesado correctamente', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const processCompanyEmployeesImages = createAsyncThunk('companies/processCompanyEmployeesImages', ({ id, images }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  processCompanyEmployeesBatchImages(token, id, images)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => dispatch(showAlert({ message: 'Imágenes procesadas correctamente', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const processCompanyEmployeesNotices = createAsyncThunk('companies/processCompanyEmployeesNotices', ({ id, notices }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  processCompanyEmployeesBatchNotices(token, id, notices)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => dispatch(showAlert({ message: 'Tratamientos procesados correctamente', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
