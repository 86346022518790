import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Typography } from '@mui/material';
import PaymentsListTable from './PaymentsListTable';
import PaymentsFilter from './PaymentsFilter';
import AppPagination from '../../../components/AppPagination';

import { resetPaymentsState } from '../../../slices/paymentsSlice';
import { getPaymentsList } from '../../../actions/paymentsActions';
import { getBundlesList } from '../../../actions/bundlesActions';
import { resetBundlesState } from '../../../slices/bundlesSlice';

const defaultFilter = {
  bundle: '',
};

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem'
  }
}

const Payments = () => {
  const dispatch = useDispatch();
  const payments = useSelector(state => state.payments.list);
  
  const [filter, setFilter] = useState({ ...defaultFilter });
  const [pagination, setPagination] = useState({ page: 1 });

  useEffect(() => {
    setPagination({ page: 1 });
    dispatch(getBundlesList({ query: { results: 9999 } }));
    return () => {
      dispatch(resetPaymentsState());
      dispatch(resetBundlesState());
    }
  }, []);

  useEffect(() => {
    const f = { page: pagination.page };

    filter.bundle.length > 0 ? f.bundle = filter.bundle : null;

    dispatch(getPaymentsList({ query: f }));
  }, [pagination]);

  const handleChange = e => setFilter({ ...filter, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    setPagination({ page: 1 });
  }

  const onClear = () => setFilter({ ...defaultFilter });

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () => setPagination({ page: Math.max(pagination.page - 1, 1) });


  return (
    <>
      <Helmet>
        <title>Cristopher | Paquetes</title>
      </Helmet>
      <Box sx={styles.title}>
        <Typography variant="h5" fontWeight="bold">Paquetes</Typography>
      </Box>
      <PaymentsFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <PaymentsListTable payments={payments} />
      <AppPagination page={pagination.page} prev={prevPage} next={nextPage} />
    </>
  );
}

export default Payments;
