import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Button, Typography } from '@mui/material';
import AdminsListTable from './AdminsListTable';
import AdminsFilter from './AdminsFilter';
import AppPagination from '../../../components/AppPagination';
import AdminsCreateDialog from './AdminsCreateDialog';

import { resetAdminsState } from '../../../slices/adminsSlice';
import { getAdminsList } from '../../../actions/adminsActions';

const defaultFilter = {
  name: '',
  email: ''
};

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem'
  }
}

const Admins = () => {
  const dispatch = useDispatch();
  const admins = useSelector(state => state.admins.list);
  
  const [filter, setFilter] = useState({ ...defaultFilter });
  const [pagination, setPagination] = useState({ page: 1 });

  const [openNew, setOpenNew] = useState(false);

  useEffect(() => {
    setPagination({ page: 1 });
    return () => dispatch(resetAdminsState());
  }, []);

  useEffect(() => {
    const f = { page: pagination.page };

    filter.name.length > 0 ? f.name = filter.name : null;
    filter.email.length > 0 ? f.email = filter.email : null;

    dispatch(getAdminsList({ query: f }));
  }, [pagination]);

  const handleChange = e => setFilter({ ...filter, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    setPagination({ page: 1 });
  }

  const onClear = () => setFilter({ ...defaultFilter });

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () => setPagination({ page: Math.max(pagination.page - 1, 1) });

  const handleOpenNew = () => setOpenNew(true);
  const handleCloseNew = () => setOpenNew(false);

  return (
    <>
      <Helmet>
        <title>Cristopher | Administradores</title>
      </Helmet>
      <Box sx={styles.title}>
        <Typography variant="h5" fontWeight="bold">Administradores</Typography>
        <Button onClick={handleOpenNew}>Nuevo</Button>
      </Box>
      <AdminsFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <AdminsListTable admins={admins} />
      <AppPagination page={pagination.page} prev={prevPage} next={nextPage} />
      <AdminsCreateDialog open={openNew} onClose={handleCloseNew} />
    </>
  );
}

export default Admins;
