import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  purchases: [],
  employees: { byGender: [], byVisibility: [], byCitizenship: [] },
  queries: 0
}

const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    setPurchases: (state, { payload }) => ({ ...state, purchases: [...payload.purchases], queries: payload.queries }),
    setEmployees: (state, { payload }) => ({ ...state, employees: payload }),
    resetMetricsState: () => ({ purchases: [], queries: 0, employees: { byGender: [], byVisibility: [], byCitizenship: [] } })
  }
});

export const { setPurchases, setEmployees, resetMetricsState } = metricsSlice.actions;
export default metricsSlice.reducer;
