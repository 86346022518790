import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, TextField, Dialog, DialogContent, DialogTitle, Button, DialogActions } from '@mui/material';

import { createNewReport, getReportsList } from '../../../../actions/reportsActions';

const styles = {
  container: {
    display: 'block'
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  inputWrapper: {
    paddingX: '0.25rem'
  },
}

const EmployeeDetailsCreateReport = props => {
  const [report, setReport] = useState({
    date: new Date().toISOString().slice(0, 10),
    cause: '',
    place: '',
    dischargeDate: new Date().toISOString().slice(0, 10),
    assistants: '',
    summary: '',
    results: '',
    observations: ''
  });

  const dispatch = useDispatch();

  const handleClose = e => {
    e?.preventDefault();
    props.onClose();
  }

  const handleChange = e => {
    setReport({ ...report, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(createNewReport({ report, employmentId: props.employmentId, callback: () => {
      dispatch(getReportsList({ employmentId: props.employmentId }));
      handleClose();
    } }));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Nuevo report</DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField type="date" InputLabelProps={{ shrink: true }} label="Fecha del informe" name="date" value={report.date} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Motivo del informe" name="cause" value={report.cause} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Lugar y dirección del evento" name="place" value={report.place} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField type="date" InputLabelProps={{ shrink: true }} label="Fecha de declaración del colaborador" name="dischargeDate" value={report.dischargeDate} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField multiline rows={4} label="Nombres y cargos de los asistentes a la declaración" name="assistants" value={report.assistants} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField multiline rows={4} label="Resumen de la versión del colaborador" name="summary" value={report.summary} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField multiline rows={4} label="Resultados de la diligencia" name="results" value={report.results} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField multiline rows={4} label="Observaciones" name="observations" value={report.observations} onChange={handleChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button variant="text" onClick={handleSubmit}>Guardar</Button>
        <Button variant="text" onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmployeeDetailsCreateReport;
