import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import PaymentsListItem from './PaymentsListItem';

const PaymentsListTable = props => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Comprador</TableCell>
            <TableCell>Paquete</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Pago</TableCell>
            <TableCell>Precio</TableCell>
            <TableCell>Creado en</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.payments?.map(payment => <PaymentsListItem key={payment._id} {...payment} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default PaymentsListTable;
