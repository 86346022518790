import { Box, Grid, Typography } from '@mui/material';
import dateFormatter from '../../../../formatters/dateFormatter';

const { REACT_APP_STORAGE_URL } = process.env;

const styles = {
  message: {
    border: 'blue solid 2px',
    borderColor: 'primary.main',
    p: '1rem',
    borderRadius: '10px',
    mb: '1rem',
  }
}

const TicketDetailsMessage = props => {
  return (
    <Grid item xs={12}>
      <Box sx={styles.message}>
        <Typography variant="h6" fontWeight="bold">{props.senderType === 'Admin' ? 'Administración' : props.username} dice</Typography>
        <Typography variant="caption">{dateFormatter(props.createdAt ?? null)}</Typography>
        <Typography sx={{ mt: '0.5rem' }} dangerouslySetInnerHTML={{ __html: props.message.replace(/\n/g, '<br>') }}></Typography>
        {props.filepath ? <a target="_blank" rel="noreferrer" href={`${REACT_APP_STORAGE_URL}${props.filepath}`}>{props.filepath.split('/').pop()}</a> : null}
      </Box>
    </Grid>
  );
}

export default TicketDetailsMessage;
