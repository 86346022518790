import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Grid, Typography } from '@mui/material';
import BundleDetailsForm from './BundleDetailsForm';
import BundleDetailsPreview from './BundleDetailsPreview';

import { getCurrentBundle, updateCurrentBundle } from '../../../../actions/bundlesActions';
import { resetBundlesState } from '../../../../slices/bundlesSlice';

const styles = {
  title: {
    marginBottom: '1rem'
  },
  column: {
    paddingX: '1rem'
  }
}

const BundleDetails = () => {
  const params = useParams();

  const dispatch = useDispatch();

  const current = useSelector(state => state.bundles.current);

  useEffect(() => {
    dispatch(getCurrentBundle({ id: params.id }));
    return () => dispatch(resetBundlesState())
  }, []);

  const [bundle, setBundle] = useState({
    _id: '',
    name: '',
    type: 'Consultas',
    description: '',
    price: '',
    iva: '',
    queries: ''
  });
    
  useEffect(() => setBundle({
    ...bundle,
    ...current,
  }), [current]);

  const handleChange = e => {
    setBundle({ ...bundle, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();

    const { name, type, description, price, iva, queries } = bundle;

    dispatch(updateCurrentBundle({
      id: bundle._id,
      bundle: { name, type, description, price, iva, queries }
    }));
  }

  return (
    <>
      <Helmet>
        <title>Cristopher | Paquete {current.name ?? ''}</title>
      </Helmet>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Paquete {current.name ?? params.id}</Typography>
      <Grid container>
        <Grid item xs={12} md={7} sx={styles.column}>
          <BundleDetailsForm {...bundle} onChange={handleChange} onSubmit={handleSubmit} />
        </Grid>
        <Grid item xs={12} md={5} sx={styles.column}>
          <BundleDetailsPreview {...bundle} />
        </Grid>
      </Grid>
    </>
  );
}

export default BundleDetails;
