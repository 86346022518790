import { TableRow, TableCell } from '@mui/material';
import dateFormatter from '../../../formatters/dateFormatter';

const QueriesListItem = props => {
  return (
    <TableRow>
      <TableCell>Cédula: {props.query} {props.pin ? `, Pin: ${props.pin}` : ''}</TableCell>
      <TableCell>{props.userRef?.name}</TableCell>
      <TableCell>{props.status}</TableCell>
      <TableCell>{dateFormatter(props.createdAt ?? null)}</TableCell>
    </TableRow>
  );
}

export default QueriesListItem;
