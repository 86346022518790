import { TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';
import dateFormatter from '../../../formatters/dateFormatter';
import currencyFormatter from '../../../formatters/currencyFormatter';

const PaymentsListItem = props => {
  console.log(props)

  return (
    <TableRow>
      <TableCell>{props._id}</TableCell>
      <TableCell><Link to={`/dashboard/usuarios/${props.userRef?._id}`}>{props.userRef?.name}</Link></TableCell>
      <TableCell><Link to={`/dashboard/paquetes/${props.bundleRef?._id}`}>{props.bundleRef?.name}</Link></TableCell>
      <TableCell>{props.bundleRef?.type}</TableCell>
      <TableCell>{props.paid ? 'Pagado' : 'Sin pagar'}</TableCell>
      <TableCell>{currencyFormatter.format(props.amount)}</TableCell>
      <TableCell>{dateFormatter(props.createdAt ?? null)}</TableCell>
    </TableRow>
  );
}

export default PaymentsListItem;
