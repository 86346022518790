import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Button } from '@mui/material';
import { AccountCircle, Apartment, AssignmentInd, Badge, CardMembership, ConfirmationNumber, Insights, Pageview, Paid, PersonAdd, Settings, VerifiedUser } from '@mui/icons-material';

import { ADMIN_LEVELS } from '../constants/adminsConstants';
import { logout } from '../actions/authActions';

const items = [
  { text: 'Administradores', icon: <Badge />, path: '/dashboard/administradores', protected: true },
  { text: 'Empleados', icon: <AssignmentInd />, path: '/dashboard/empleados', protected: false },
  { text: 'Empresas', icon: <Apartment />, path: '/dashboard/empresas', protected: false },
  { text: 'Usuarios', icon: <AccountCircle />, path: '/dashboard/usuarios', protected: false },
  { text: 'Prospectos', icon: <PersonAdd />, path: '/dashboard/prospectos', protected: false },
  { text: 'Paquetes', icon: <CardMembership />, path: '/dashboard/paquetes', protected: false },
  { text: 'Tickets', icon: <ConfirmationNumber />, path: '/dashboard/tickets', protected: false },
  { text: 'Consultas', icon: <Pageview />, path: '/dashboard/consultas', protected: false },
  { text: 'Pagos', icon: <Paid />, path: '/dashboard/pagos', protected: true },
  { text: 'Métricas', icon: <Insights />, path: '/dashboard/metricas', protected: true },
  { text: 'Política y términos', icon: <VerifiedUser />, path: '/dashboard/politicas', protected: false },
  { text: 'Configuración', icon: <Settings />, path: '/dashboard/configuracion', protected: true },
];

const styles = {
  drawer: {
    width: 230,
    flex: 1
  },
  logoWrapper: {
    justifyContent: 'center'
  },
  logo: {
    width: '75%',
    cursor: 'pointer'
  },
  dividerWrapper: {
    marginY: '0.75rem',
    paddingX: '1rem'
  },
  buttonWrapper: {
    textAlign: 'center',
    padding: '1rem'
  }
}

const AppDrawer = props => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector(state => state.auth.data);

  const handleNavigate = path => () => {
    navigate(path);
    props.onClose();
  }

  const handleLogout = () => {
    dispatch(logout({ callback: () => navigate('/login') }));
  }

  return (
    <Drawer {...props}>
      <Box
        sx={styles.drawer}
        role="presentation"
      >
        <List>
          <ListItem sx={styles.logoWrapper}>
            <img src="/img/logo.png" alt="logo" style={styles.logo} onClick={handleNavigate("")} />
          </ListItem>
          <Box sx={styles.dividerWrapper}>
            <Divider />
          </Box>
          {items.filter(item => data?.level === ADMIN_LEVELS.SUPERADMIN || !item.protected).map(item => 
            <ListItemButton key={item.text} onClick={handleNavigate(item.path)}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItemButton>
          )}
        </List>
      </Box>
      <Box sx={styles.buttonWrapper}>
        <Button fullWidth variant="text" onClick={handleLogout}>Cerrar sesión</Button>
      </Box>
    </Drawer>
  );
}

export default AppDrawer;

