import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Button, Typography } from '@mui/material';
import CompaniesListTable from './CompaniesListTable';
import CompaniesFilter from './CompaniesFilter';
import CompaniesCreateDialog from './CompaniesCreateDialog';
import AppPagination from '../../../components/AppPagination';

import { resetCompaniesState } from '../../../slices/companiesSlice';
import { getCompaniesList, getCompaniesMeta, downloadCompaniesCsv } from '../../../actions/companiesActions';

const defaultFilter = {
  name: '',
  city: '',
  email: '',
};

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem'
  }
}

const Companies = () => {
  const dispatch = useDispatch();
  const companies = useSelector(state => state.companies.list);
  const meta = useSelector(state => state.companies.meta);
  
  const [filter, setFilter] = useState({ ...defaultFilter });
  const [pagination, setPagination] = useState({ page: 1 });

  const [openNew, setOpenNew] = useState(false);

  useEffect(() => {
    dispatch(getCompaniesMeta());
    setPagination({ page: 1 });
    return () => dispatch(resetCompaniesState());
  }, []);

  useEffect(() => {
    const f = { page: pagination.page };

    filter.name.length > 0 ? f.name = filter.name : null;
    filter.city.length > 0 ? f.city = filter.city : null;
    filter.email.length > 0 ? f.email = filter.email : null;

    dispatch(getCompaniesList({ query: f }));
  }, [pagination]);

  const handleChange = e => setFilter({ ...filter, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    setPagination({ page: 1 });
  }
  
  const handleDownload = e => {
    e.preventDefault();

    const f = { page: pagination.page };

    filter.name.length > 0 ? f.name = filter.name : null;
    filter.city.length > 0 ? f.city = filter.city : null;
    filter.email.length > 0 ? f.email = filter.email : null;

    dispatch(downloadCompaniesCsv({ query: f }));
  }

  const onClear = () => setFilter({ ...defaultFilter });

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () => setPagination({ page: Math.max(pagination.page - 1, 1) });

  const handleOpenNew = () => setOpenNew(true);
  const handleCloseNew = () => setOpenNew(false);

  return (
    <>
      <Helmet>
        <title>Cristopher | Empresas</title>
      </Helmet>
      <Box sx={styles.title}>
        <Typography variant="h5" fontWeight="bold">Empresas (total: {meta.total ?? 0})</Typography>
        <Box>
          <Button onClick={handleOpenNew}>Nueva</Button>
          <Button onClick={handleDownload} sx={{ ml: '1rem' }}>Descargar</Button>
        </Box>
      </Box>
      <CompaniesFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <CompaniesListTable companies={companies} />
      <AppPagination page={pagination.page} prev={prevPage} next={nextPage} />
      <CompaniesCreateDialog open={openNew} onClose={handleCloseNew} />
    </>
  );
}

export default Companies;
