import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, TextField, Button} from '@mui/material';
import { SplideSlide } from '@splidejs/react-splide';

import { updateCurrentReport } from '../../../../actions/reportsActions';

const styles = {
  inputWrapper: {
    paddingX: '0.25rem'
  },
}


const EmployeeDetailsReportsSlide = props => {
  const [report, setReport] = useState({
    date: new Date(props.date).toISOString().slice(0, 10),
    cause: props.cause,
    place: props.place,
    dischargeDate: new Date(props.dischargeDate).toISOString().slice(0, 10),
    assistants: props.assistants,
    summary: props.summary,
    results: props.results,
    observations: props.observations
  });

  const dispatch = useDispatch();

  const handleChange = e => {
    setReport({ ...report, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(updateCurrentReport({ employmentId: props.employmentId, id: props._id, report }));
  }

  return (
    <SplideSlide>
      <Grid container sx={{ px: '3rem' }}>
        <Grid item xs={12} sx={styles.inputWrapper}>
          <TextField type="date" InputLabelProps={{ shrink: true }} label="Fecha del evento" name="date" value={report.date} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sx={styles.inputWrapper}>
          <TextField label="Motivo del evento" name="cause" value={report.cause} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sx={styles.inputWrapper}>
          <TextField label="Lugar y dirección del evento" name="place" value={report.place} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sx={styles.inputWrapper}>
          <TextField type="date" InputLabelProps={{ shrink: true }} label="Fecha de declaración del colaborador" name="dischargeDate" value={report.dischargeDate} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sx={styles.inputWrapper}>
          <TextField multiline rows={4} label="Nombres y cargos de los asistentes a la declaración" name="assistants" value={report.assistants} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sx={styles.inputWrapper}>
          <TextField multiline rows={4} label="Resumen de la versión del colaborador" name="summary" value={report.summary} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sx={styles.inputWrapper}>
          <TextField multiline rows={4} label="Resultados de la diligencia" name="results" value={report.results} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sx={styles.inputWrapper}>
          <TextField multiline rows={4} label="Observaciones" name="observations" value={report.observations} onChange={handleChange} />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'end' }}>
          <Button onClick={handleSubmit}>Guardar</Button>
        </Grid>
      </Grid>
    </SplideSlide>
  );
}

export default EmployeeDetailsReportsSlide;
