import { createAsyncThunk } from '@reduxjs/toolkit';
import { createAdmin, getAdmin, getAdmins, updateAdmin, resetAdminPassword } from '../api/adminsApi';
import { showAlert } from '../slices/alertsSlice';
import { setCurrentAdmin, setAdminsList } from '../slices/adminsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const getAdminsList = createAsyncThunk('admins/getAdminsList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getAdmins(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setAdminsList({ admins: data.admins })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCurrentAdmin = createAsyncThunk('admins/getCurrentAdmin', ({ id }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getAdmin(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentAdmin({ admin: data.admin })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const createNewAdmin = createAsyncThunk('admins/createNewAdmin', ({ admin, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createAdmin(token, admin)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.admin))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const updateCurrentAdmin = createAsyncThunk('admins/updateCurrentAdmin', ({ id, admin }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateAdmin(token, id, admin)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentAdmin({ admin: data.admin })))
  .then(() => dispatch(showAlert({ message: 'Administrador actualizado', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const resetCurrentAdminPassword = createAsyncThunk('admins/resetCurrentAdminPassword', ({ id, password }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  resetAdminPassword(token, id, password)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => dispatch(showAlert({ message: 'Contraseña restablecida', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
