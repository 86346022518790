import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, TextField, Dialog, DialogContent, DialogTitle, Button, DialogActions, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material';

import { updateCurrentEmployment, getEmploymentsList } from '../../../../actions/employmentsActions';

const styles = {
  container: {
    display: 'block'
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  inputWrapper: {
    paddingX: '0.25rem'
  },
}

const EmployeeDetailsEmploymentEdit = props => {
  const [employment, setEmployment] = useState({
    admissionDate: props.admissionDate ? new Date(props.admissionDate).toISOString().slice(0, 10) : '',
    departureDate: props.departureDate ? new Date(props.departureDate).toISOString().slice(0, 10) : '',
    jobArea: props.jobArea ?? '',
    firstCharge: props.firstCharge ?? '',
    lastCharge: props.lastCharge ?? '',
    supervisorName: props.supervisorName ?? '',
    supervisorEmail: props.supervisorEmail ?? '',
    supervisorPhone: props.supervisorPhone ?? '',
    departureReason: props.departureReason ?? '',
    current: props.current ?? false,
  });

  const dispatch = useDispatch();

  const params = useParams();

  const handleClose = e => {
    e?.preventDefault();
    props.onClose();
  }

  const handleChange = e => {
    setEmployment(prev => ({ ...prev, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value }));
  }

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(updateCurrentEmployment({
      employment: { ...employment, departureDate: employment.current ? null : employment.departureDate },
      id: props._id,
      callback: () => {
        dispatch(getEmploymentsList({ query: { employee: params.id } }));
        handleClose();
      }
    }));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Datos del empleo</DialogTitle>
      <DialogContent sx={styles.content}>
        <Typography>{props.companyRef?.name}</Typography>
        <FormGroup>
          <FormControlLabel control={<Checkbox name="current" checked={employment.current} onChange={handleChange} />} label="Empleo actual" />
        </FormGroup>
        <Grid container sx={{ px: '3rem' }}>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField type="date" InputLabelProps={{ shrink: true }} label="Fecha de ingreso" name="admissionDate" value={employment.admissionDate} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            {!employment.current
              ? <TextField type="date" InputLabelProps={{ shrink: true }} label="Fecha de salida" name="departureDate" value={employment.departureDate} onChange={handleChange} disabled={employment.current} />
              : <TextField label="Fecha de salida" value="Labora aquí" disabled={employment.current} />
            }
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Área laboral" name="jobArea" value={employment.jobArea} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Razón de salida" name="departureReason" value={employment.departureReason} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Cargo inicial" name="firstCharge" value={employment.firstCharge} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Cargo final" name="lastCharge" value={employment.lastCharge} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Jefe inmediato" name="supervisorName" value={employment.supervisorName} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Teléfono de referencia" name="supervisorPhone" value={employment.supervisorPhone} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Correo de referencia" name="supervisorEmail" value={employment.supervisorEmail} onChange={handleChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button variant="text" onClick={handleSubmit}>Guardar</Button>
        <Button variant="text" onClick={handleClose}>Cerrar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmployeeDetailsEmploymentEdit;
