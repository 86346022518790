import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import ProspectDetailsForm from './ProspectDetailsForm';

import { getCurrentProspect } from '../../../../actions/prospectsActions';
import { resetProspectsState } from '../../../../slices/prospectsSlice';
import { resetCompaniesState } from '../../../../slices/companiesSlice';

const styles = {
  title: {
    marginBottom: '1rem'
  },
  column: {
    paddingX: '1rem'
  }
}

const ProspectDetails = () => {
  const params = useParams();

  const dispatch = useDispatch();

  const current = useSelector(state => state.prospects.current);

  useEffect(() => {
    dispatch(getCurrentProspect({ id: params.id }));
    
    return () => {
      dispatch(resetProspectsState());
      dispatch(resetCompaniesState());
    }
  }, []);

  return (
    <>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Prospecto {current.name ?? params.id} ha consultado {current.count} {current.count === 1 ? 'vez' : 'veces'}</Typography>
      <Grid container>
        <Grid item xs={12} sx={styles.column}>
          <ProspectDetailsForm />
        </Grid>
      </Grid>
    </>
  );
}

export default ProspectDetails;
