import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { getEnvsList } from '../../../actions/envsActions';
import { resetEnvsState } from '../../../slices/envsSlice';
import SettingsEnvBlock from './SettingsEnvBlock';

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem'
  }
}

const Settings = () => {
  const dispatch = useDispatch();
  const envs = useSelector(state => state.envs.list);

  useEffect(() => {
    dispatch(getEnvsList());
    return () => dispatch(resetEnvsState());
  }, []);

  return (
    <>
      <Helmet>
        <title>Cristopher | Configuración</title>
      </Helmet>
      <Box sx={styles.title}>
        <Typography variant="h5" fontWeight="bold">Configuración</Typography>
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Variable</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Guardar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {envs.map(env => <SettingsEnvBlock key={env._id} {...env} />)}
        </TableBody>
      </Table>
    </>
  );
}

export default Settings;
