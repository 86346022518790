import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Assignment, Image, List } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography } from '@mui/material';

import { processCompanyEmployeesCsv, processCompanyEmployeesImages, processCompanyEmployeesNotices } from '../../../../actions/companiesActions';

const styles = {
  container: {
    display: 'block',
    textAlign: 'center'
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  column: {
    px: '1rem'
  },
  submitButton: {
    mt: '2rem'
  }
}

const EmployeeCreateFromCsv = props => {
  const [files, setFiles] = useState({
    csv: null,
    images: null,
    notices: null
  });
  const [companyRef, setCompanyRef] = useState(props.companyRef);

  useEffect(() => {
    setCompanyRef(props.companyRef);
  }, [props.companyRef]);

  const dispatch = useDispatch();

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  const handleFileChange = e => {
    setFiles(prev => ({ ...prev, [e.target.name]: e.target.files[0] }));
  }

  const handleCompanyChange = e => {
    setCompanyRef(e.target.value);
  }

  const handleSubmitCsv = e => {
    e.preventDefault();
    dispatch(processCompanyEmployeesCsv({ id: companyRef, csv: files.csv }));
  }

  const handleSubmitImages = e => {
    e.preventDefault();
    dispatch(processCompanyEmployeesImages({ id: companyRef, images: files.images }));
  }

  const handleSubmitNotices = e => {
    e.preventDefault();
    dispatch(processCompanyEmployeesNotices({ id: companyRef, notices: files.notices }));
  }


  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Importar desde archivos</DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.container}>
          <TextField select label="Empresa" name="companyRef" value={companyRef} onChange={handleCompanyChange}>
            {props.companies.map(company => <MenuItem key={company._id} value={company._id}>{company.name}</MenuItem>)}
          </TextField>
          <Grid container>
            <Grid item xs={12} md={4} sx={styles.column}>
              <Typography>Seleccione CSV de datos</Typography>
              <Button variant="outlined" color="primary" component="label" size="large" sx={styles.iconButton}>
                <input hidden accept="text/csv" type="file" name="csv" onChange={handleFileChange} />
                <List />
              </Button>
              <Typography variant="caption" fontWeight="bold" display="block">{files.csv?.name ?? 'Sin seleccionar'}</Typography> 
              <Button onClick={handleSubmitCsv} disabled={!files.csv} sx={styles.submitButton}>Cargar</Button>
            </Grid>
            <Grid item xs={12} md={4} sx={styles.column}>
              <Typography>Seleccione ZIP de imágenes</Typography>
              <Button variant="outlined" color="primary" component="label" size="large" sx={styles.iconButton}>
                <input hidden accept="application/zip" type="file" name="images" onChange={handleFileChange} />
                <Image />
              </Button>
              <Typography variant="caption" fontWeight="bold" display="block">{files.images?.name ?? 'Sin seleccionar'}</Typography> 
              <Button onClick={handleSubmitImages} disabled={!files.images} sx={styles.submitButton}>Cargar</Button>
            </Grid>
            <Grid item xs={12} md={4} sx={styles.column}>
              <Typography>Seleccione ZIP de tratamiento de datos</Typography>
              <Button variant="outlined" color="primary" component="label" size="large" sx={styles.iconButton}>
                <input hidden accept="application/zip" type="file" name="notices" onChange={handleFileChange} />
                <Assignment />
              </Button>
              <Typography variant="caption" fontWeight="bold" display="block">{files.notices?.name ?? 'Sin seleccionar'}</Typography>
              <Button onClick={handleSubmitNotices} disabled={!files.notices} sx={styles.submitButton}>Cargar</Button>
            </Grid>
          </Grid>
          <Box sx={{ my: '0.75rem' }}>
            <a href="/autorizacion.pdf" target="_blank" rel="noreferrer">Descargar formato tratamiento de datos</a>
          </Box>
          <Box>
            <a href="/ejemplo.xlsx" target="_blank" rel="noreferrer">Descargar ejemplo tabla de datos</a>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button variant="text" onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EmployeeCreateFromCsv;
