import { combineReducers, configureStore } from '@reduxjs/toolkit';
import alertsSlice from '../slices/alertsSlice';
import authSlice from '../slices/authSlice';
import loadingSlice from '../slices/loadingSlice';
import errorsSlice from '../slices/errorsSlice';
import adminsSlice from '../slices/adminsSlice';
import usersSlice from '../slices/usersSlice';
import prospectsSlice from '../slices/prospectsSlice';
import employeesSlice from '../slices/employeesSlice';
import companiesSlice from '../slices/companiesSlice';
import employmentsSlice from '../slices/employmentsSlice';
import bundlesSlice from '../slices/bundlesSlice';
import negativesSlice from '../slices/negativesSlice';
import changesSlice from '../slices/changesSlice';
import ticketsSlice from '../slices/ticketsSlice';
import reportsSlice from '../slices/reportsSlice';
import queriesSlice from '../slices/queriesSlice';
import paymentsSlice from '../slices/paymentsSlice';
import metricsSlice from '../slices/metricsSlice';
import notificationsSlice from '../slices/notificationsSlice';
import envsSlice from '../slices/envsSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  alerts: alertsSlice,
  loading: loadingSlice,
  errors: errorsSlice,
  admins: adminsSlice,
  users: usersSlice,
  prospects: prospectsSlice,
  employees: employeesSlice,
  companies: companiesSlice,
  employments: employmentsSlice,
  bundles: bundlesSlice,
  negatives: negativesSlice,
  changes: changesSlice,
  tickets: ticketsSlice,
  reports: reportsSlice,
  queries: queriesSlice,
  payments: paymentsSlice,
  metrics: metricsSlice,
  notifications: notificationsSlice,
  envs: envsSlice,
});

const store = configureStore({ reducer: rootReducer });

export default store;
