import { Button, Card, CardContent, CardHeader, Checkbox, Collapse, FormControlLabel, FormGroup, Grid, MenuItem, TextField } from '@mui/material';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
}

const EmployeeCreateEmployment = props => {
  return (
    <Card sx={styles.card}>
      <CardHeader title="Información del cargo" />
      <Collapse in={props.show}>
        <CardContent>
          <Grid container sx={{ alignItems: 'flex-end' }}>
            <Grid item xs={12} md={4} sx={styles.inputWrapper}>
              <TextField select label="Empresa" name="companyRef" value={props.companyRef} onChange={props.onChange}>
                {props.companies.map(company => <MenuItem key={company._id} value={company._id}>{company.name}</MenuItem>)}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4} sx={styles.inputWrapper}>
              <TextField type="date" InputLabelProps={{ shrink: true }} label="Fecha de ingreso" name="admissionDate" value={props.admissionDate} onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={4} sx={styles.inputWrapper}>
              <FormGroup>
                <FormControlLabel control={<Checkbox name="current" checked={props.current} onChange={props.onChange} />} label="Empleo actual" />
              </FormGroup>
              <TextField type="date" InputLabelProps={{ shrink: true }} label="Fecha de egreso" name="departureDate" value={props.departureDate} onChange={props.onChange} disabled={props.current} />
            </Grid>
            <Grid item xs={12} md={3} sx={styles.inputWrapper}>
              <TextField label="Área laboral" name="jobArea" value={props.jobArea} onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={3} sx={styles.inputWrapper}>
              <TextField label="Cargo inicial" name="firstCharge" value={props.firstCharge} onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={3} sx={styles.inputWrapper}>
              <TextField label="Cargo final" name="lastCharge" value={props.lastCharge} onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={3} sx={styles.inputWrapper}>
              <TextField label="Periodo" name="period" value={props.period} onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={4} sx={styles.inputWrapper}>
              <TextField label="Nombre de supervisor" name="supervisorName" value={props.supervisorName} onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={4} sx={styles.inputWrapper}>
              <TextField label="Teléfono de supervisor" name="supervisorPhone" value={props.supervisorPhone} onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} md={4} sx={styles.inputWrapper}>
              <TextField label="Correo de supervisor" name="supervisorEmail" value={props.supervisorEmail} onChange={props.onChange} />
            </Grid>
            <Grid item xs={12} sx={styles.inputWrapper}>
              <TextField multiline rows="3" label="Razón de egreso" name="departureReason" value={props.departureReason} onChange={props.onChange} />
            </Grid>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button color="primary" onClick={props.onSubmit}>Guardar</Button>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default EmployeeCreateEmployment;
