const { REACT_APP_API_BASE_URL } = process.env;

export const getTickets = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    results: 10,
    ...query
  }).toString();
  
  const request = new Request(REACT_APP_API_BASE_URL + '/tickets?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getTicket = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/tickets/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createTicket = (token, ticket) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/tickets', {
    method: 'POST',
    body: JSON.stringify({ ticket }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updateTicket = (token, id, ticket) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/tickets/' + id, {
    method: 'PUT',
    body: JSON.stringify({ ticket }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createTicketMessage = (token, id, data) => new Promise((resolve, reject) => {
  const request = new Request(`${REACT_APP_API_BASE_URL}/tickets/${id}/message`, {
    method: 'POST',
    body: JSON.stringify({ data }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const uploadTicketMessageFile = (token, id, file) => new Promise((resolve, reject) => {
  const data = new FormData();
  data.append('file', file);

  const request = new Request(`${REACT_APP_API_BASE_URL}/tickets/${id}/message/file`, {
    method: 'POST',
    body: data,
    headers: {
      'Authorization': 'Bearer ' + token,
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
