import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current: {},
  list: []
}

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setPaymentsList: (state, { payload }) => ({ ...state, list: [...payload.payments] }),
    setCurrentPayment: (state, { payload }) => ({ ...state, current: { ...payload.payment } }),
    resetPaymentsState: () => ({ list: [], current: { } })
  }
});

export const { setPaymentsList, setCurrentPayment, resetPaymentsState } = paymentsSlice.actions;
export default paymentsSlice.reducer;
