import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, Box, Button, DialogActions, Typography } from '@mui/material';
import { getCurrentTicket, uploadNewTicketMessageFile } from '../../../../actions/ticketsActions';

const styles = {
  container: {
    display: 'block',
    textAlign: 'center'
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  }
}

const TicketDetailsUploadFile = props => {
  const [file, setFile] = useState(null);

  const dispatch = useDispatch();

  const params = useParams();

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  const handleChange = e => setFile(e.target.files[0]);

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(uploadNewTicketMessageFile({ id: params.id, file, callback: () => {
      dispatch(getCurrentTicket({ id: params.id, callback: props.scrollCallback }));
      setFile(null);
      props.onClose();
    } }));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Cargar archivo adjunto</DialogTitle>
      <DialogContent sx={styles.content}>
        <Box sx={styles.container}>
          <Typography>Seleccione un archivo para cargar</Typography>
          <Box>
            <Button component="label" variant="outlined">
              Seleccionar archivo
              <input type="file" hidden={true} onChange={handleChange} />
            </Button>
          </Box>
          {file ? <Typography>{file.name}</Typography> : null}
          <Box>
            <Button onClick={handleSubmit} disabled={!file}>Subir archivo</Button>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={styles.actions}>
        <Button variant="text" onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default TicketDetailsUploadFile;
