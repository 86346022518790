import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Edit, Save } from '@mui/icons-material';
import { Box, Divider, IconButton, ListItem, TextField, Typography } from '@mui/material';
import { updateCurrentNegative } from '../../../../actions/negativesActions';

const styles = {
  inputWrapper: {
    paddingX: '0.25rem'
  },
}

const EmployeeDetailsNegativeItem = ({ negative }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState({ date: negative.date.slice(0, 10), event: negative.event });

  const dispatch = useDispatch();
  const params = useParams();

  const handleEditButton = () => setIsEditing(true);

  const handleChange = e => setData({ ...data, [e.target.name]: e.target.value });

  const handleSave = () => {
    dispatch(updateCurrentNegative({ employeeId: params.id, negativeId: negative._id, negative: data }));
    setIsEditing(false);
  }

  return (
    <Box key={negative._id}>
      <ListItem>
        {isEditing
        ? <>
            <Box sx={styles.inputWrapper}><TextField type="date"InputLabelProps={{ shrink: true }} label="Fecha" name="date" value={data.date} onChange={handleChange}></TextField></Box>
            <Box sx={{ ...styles.inputWrapper, flex: 1, minWidth: '200px' }}><TextField label="Evento" name="event" value={data.event} onChange={handleChange}></TextField></Box>
            <IconButton onClick={handleSave}><Save /></IconButton>
          </>
        : <>
            <Typography>{new Date(data.date).toLocaleDateString()} - {data.event}</Typography>
            <IconButton onClick={handleEditButton}><Edit /></IconButton>
          </>
        }
      </ListItem>
      <Divider />
    </Box>
  );
}

export default EmployeeDetailsNegativeItem;
