import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, TextField, Dialog, DialogContent, DialogTitle, Button, DialogActions, MenuItem, Typography } from '@mui/material';

import { createNewCompany } from '../../../actions/companiesActions';

const styles = {
  container: {
    display: 'block'
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  inputWrapper: {
    paddingX: '0.25rem'
  },
}

const CompaniesCreateDialog = props => {
  const [company, setCompany] = useState({
    name: '',
    address: '',
    city: '',
    phoneNumber: '',
    email: '',
    taxType: '',
    nit: '',
    contactName: '',
    contactPhoneNumber: '',
    contactEmail: '',
    internal: false,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  const handleChange = e => {
    setCompany({ ...company, [e.target.name]: e.target.value });
  }

  const handleRedirect = id => {
    navigate('/dashboard/empresas/' + id);
  }

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(createNewCompany({ company, callback: handleRedirect }));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Nueva empresa</DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Nombre" name="name" value={company.name} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Ciudad" name="city" value={company.city} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={12} sx={styles.inputWrapper}>
            <TextField label="Dirección" name="address" value={company.address} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Correo electrónico" name="email" value={company.email} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Teléfono móvil" name="phoneNumber" value={company.phoneNumber} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="NIT" name="nit" value={company.nit} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField select label="Tipo de contribuyente" name="taxType" value={company.taxType} onChange={handleChange}>
              <MenuItem value="Persona Natural">Persona Natural</MenuItem>
              <MenuItem value="Persona Jurídica">Persona Jurídica</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField select label="Visibilidad" name="internal" value={company.internal} onChange={handleChange}>
              <MenuItem value={false}>Pública</MenuItem>
              <MenuItem value={true}>Privada</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sx={styles.contact}>
            <Typography variant="h6">Contacto</Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Nombre de contacto" name="contactName" value={company.contactName} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Correo de contacto" name="contactEmail" value={company.contactEmail} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Teléfono de contacto" name="contactPhoneNumber" value={company.contactPhoneNumber} onChange={handleChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
      <Button variant="text" onClick={handleSubmit}>Guardar</Button>
        <Button variant="text" onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default CompaniesCreateDialog;
