import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current: {},
  list: []
}

const queriesSlice = createSlice({
  name: 'queries',
  initialState,
  reducers: {
    setQueriesList: (state, { payload }) => ({ ...state, list: [...payload.queries] }),
    setCurrentQuery: (state, { payload }) => ({ ...state, current: { ...payload.query } }),
    resetQueriesState: () => ({ list: [], current: { }, statistics: { }, meta: { } })
  }
});

export const { setQueriesList, setCurrentQuery, resetQueriesState } = queriesSlice.actions;
export default queriesSlice.reducer;
