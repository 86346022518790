const { REACT_APP_API_BASE_URL } = process.env;

export const getPayments = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    results: 10,
    ...query
  }).toString();
  
  const request = new Request(REACT_APP_API_BASE_URL + '/payments?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getPayment = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/payments/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createPayment = (token, payment) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/payments', {
    method: 'POST',
    body: JSON.stringify({ payment }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updatePayment = (token, id, payment) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/payments/' + id, {
    method: 'PUT',
    body: JSON.stringify({ payment }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
