import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import Chart from 'chart.js/auto';
import { Box } from '@mui/material';

const MetricsPurchasesChart = () => {
  const purchases = useSelector(state => state.metrics.purchases);
  const queries = useSelector(state => state.metrics.queries);
  const chartRef = useRef();

  const config = useMemo(() => {
    return {
      type: 'bar',
      data: {
        labels: ['Datos totales'],
        datasets: [
          ...purchases.map(data => ({
            label: data.bundleRef.name,
            data: [data.count],
            stack: 'Paquetes'
          })),
          {
            label: 'Consultas realizadas',
            data: [queries],
            stack: 'Consultas'
          }
        ]
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Relación de paquetes y consultas'
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true
          },
          y: {
            beginAtZero: true
          }
        }
      }
    };
  }, [purchases, queries]);

  useEffect(() => {
    const chart = new Chart(chartRef.current, config);

    return () => chart.destroy();
  }, [config, chartRef]);

  return <Box sx={{ marginY: '1rem' }}><canvas ref={chartRef} /></Box>
}

export default MetricsPurchasesChart;
