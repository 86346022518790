import { createAsyncThunk } from '@reduxjs/toolkit';
import { createTicket, createTicketMessage, getTicket, getTickets, updateTicket, uploadTicketMessageFile } from '../api/ticketsApi';
import { showAlert } from '../slices/alertsSlice';
import { setCurrentTicket, setTicketsList } from '../slices/ticketsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const getTicketsList = createAsyncThunk('tickets/getTicketsList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getTickets(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setTicketsList({ tickets: data.tickets })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCurrentTicket = createAsyncThunk('tickets/getCurrentTicket', ({ id, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getTicket(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentTicket({ ticket: data.ticket })))
  .then(() => callback?.())
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const createNewTicket = createAsyncThunk('tickets/createNewTicket', ({ ticket, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createTicket(token, ticket)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.ticket))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const updateCurrentTicket = createAsyncThunk('tickets/updateCurrentTicket', ({ id, ticket }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateTicket(token, id, ticket)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentTicket({ ticket: data.ticket })))
  .then(() => dispatch(showAlert({ message: 'Ticket actualizado', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const createNewTicketMessage = createAsyncThunk('tickets/createNewTicketMessage', ({ id, message, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createTicketMessage(token, id, { message })
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.ticket))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const uploadNewTicketMessageFile = createAsyncThunk('tickets/uploadNewTicketMessageFile', ({ id, file, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  uploadTicketMessageFile(token, id, file)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(() => dispatch(showAlert({ message: 'Archivo cargado correctamente', type: 'success' })))
  .then(() => callback())
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
