import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current: {},
  list: [],
}

const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    setAdminsList: (state, { payload }) => ({ ...state, list: [...payload.admins] }),
    setCurrentAdmin: (state, { payload }) => ({ ...state, current: { ...payload.admin } }),
    resetAdminsState: () => ({ list: [], current: { } })
  }
});

export const { setAdminsList, setCurrentAdmin, resetAdminsState } = adminsSlice.actions;
export default adminsSlice.reducer;
