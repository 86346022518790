import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Box, MenuItem, Typography } from '@mui/material';
import { readCurrentNotification } from '../actions/notificationsActions';
import dateFormatter from '../formatters/dateFormatter';

const AppNotificationTile = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const route = useMemo(() => {
    switch(props.docType) {
      case 'Ticket': return '/dashboard/tickets/' + props.docRef;
      case 'Employee': return '/dashboard/empleados/' + props.docRef;
      default: return '/dashboard/tickets';
    }
  }, [props.docRef]);

  const handleClick = () => {
    dispatch(readCurrentNotification({ id: props._id }));
    navigate(route);
    props.onClose();
  }

  return (
    <MenuItem onClick={handleClick} sx={{ backgroundColor: props.read ? 'white' : '#EEE' }}>
      <Box>
        <Typography variant="body1">{props.title}</Typography>
        <Typography variant="body2">{props.content?.length > 25 ? `${props.content.substr(0, 25)}...` : props.content}</Typography>
        <Typography variant="caption">{dateFormatter(props.createdAt ?? null)}</Typography>
      </Box>
    </MenuItem>
  );
}

export default AppNotificationTile;
