import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { updateCurrentEnv } from '../../../actions/envsActions';

const SettingsEnvBlock = props => {
  const [value, setValue] = useState(props.value);

  const dispatch = useDispatch();

  const handleChange = e => setValue(e.target.value);

  const handleSave = e => {
    e.preventDefault();

    dispatch(updateCurrentEnv({ id: props.label, env: { value } }));
  }

  return (
    <TableRow>
      <TableCell><Typography>{props.display}</Typography></TableCell>
      <TableCell><TextField placeholder={props.display} value={value} onChange={handleChange} /></TableCell>
      <TableCell><Button onClick={handleSave}>Guardar</Button></TableCell>
    </TableRow>
  );
}

export default SettingsEnvBlock;
