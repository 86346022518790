import { createAsyncThunk } from '@reduxjs/toolkit';
import { uploadPrivacy, uploadTerms } from '../api/docsApí';
import { showAlert } from '../slices/alertsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const uploadPrivacyFile = createAsyncThunk('employees/uploadPrivacyFile', ({ file, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  uploadPrivacy(token, file)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => new Promise(resolve => setTimeout(resolve, 1500)))
  .then(() => dispatch(showAlert({ message: 'Políticas actualizadas', type: 'success' })))
  .then(() => callback())
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const uploadTermsFile = createAsyncThunk('employees/uploadTermsFile', ({ file, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  uploadTerms(token, file)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(() => new Promise(resolve => setTimeout(resolve, 1500)))
  .then(() => dispatch(showAlert({ message: 'Términos actualizados', type: 'success' })))
  .then(() => callback())
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
