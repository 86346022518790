import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Grid, TextField, Dialog, DialogContent, DialogTitle, Button, DialogActions, MenuItem } from '@mui/material';

import { createNewBundle } from '../../../actions/bundlesActions';

const styles = {
  container: {
    display: 'block'
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
  inputWrapper: {
    paddingX: '0.25rem'
  },
}

const BundlesCreateDialog = props => {
  const [bundle, setBundle] = useState({
    name: '',
    type: 'Consultas',
    description: '',
    price: '',
    iva: '',
    queries: '',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  const handleChange = e => {
    setBundle({ ...bundle, [e.target.name]: e.target.value });
  }

  const handleRedirect = id => {
    navigate('/dashboard/paquetes/' + id);
  }

  const handleSubmit = e => {
    e.preventDefault();

    dispatch(createNewBundle({ bundle, callback: handleRedirect }));
  }

  return (
    <Dialog
      open={props.open}
      onClose={handleClose}
    >
      <DialogTitle>Nuevo paquete</DialogTitle>
      <DialogContent sx={styles.content}>
        <Grid container>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField label="Nombre" name="name" value={bundle.name} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField select label="Tipo" name="type" value={bundle.type} onChange={handleChange}>
              <MenuItem value="Consultas">Premium</MenuItem>
              <MenuItem value="Básicas">Básicas</MenuItem>
              <MenuItem value="Consultas Específicas">Consultas Específicas</MenuItem>
              <MenuItem value="Pruebas de Confiabilidad">Pruebas de Confiabilidad</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField type="number" label="Precio final" name="price" value={bundle.price} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField type="number" label="IVA" name="iva" value={bundle.iva} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField type="number" label="Número de consultas o pruebas" name="queries" value={bundle.queries} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} sx={styles.inputWrapper}>
            <TextField multiline rows={3} label="Descripción" name="description" value={bundle.description} onChange={handleChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={styles.actions}>
      <Button variant="text" onClick={handleSubmit}>Guardar</Button>
        <Button variant="text" onClick={handleClose}>Cancelar</Button>
      </DialogActions>
    </Dialog>
  );
}

export default BundlesCreateDialog;
