const { REACT_APP_API_BASE_URL } = process.env;

export const getAdmins = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    results: 10,
    ...query
  }).toString();
  const request = new Request(REACT_APP_API_BASE_URL + '/admins?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getAdmin = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/admins/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createAdmin = (token, admin) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/admins', {
    method: 'POST',
    body: JSON.stringify({ admin }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updateAdmin = (token, id, admin) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/admins/' + id, {
    method: 'PUT',
    body: JSON.stringify({ admin }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const resetAdminPassword = (token, id, password) => new Promise((resolve, reject) => {
  const request = new Request(`${REACT_APP_API_BASE_URL}/admins/${id}/reset-password`, {
    method: 'PUT',
    body: JSON.stringify({ data: { password } }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
