const { REACT_APP_API_BASE_URL } = process.env;

export const uploadPrivacy = (token, file) => new Promise((resolve, reject) => {
  const data = new FormData();
  data.append('file', file);

  const request = new Request(`${REACT_APP_API_BASE_URL}/docs/policy`, {
    method: 'POST',
    body: data,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const uploadTerms = (token, file) => new Promise((resolve, reject) => {
  const data = new FormData();
  data.append('file', file);

  const request = new Request(`${REACT_APP_API_BASE_URL}/docs/terms`, {
    method: 'POST',
    body: data,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

