import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current: {},
  statistics: {},
  meta: {},
  list: []
}

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setCompaniesList: (state, { payload }) => ({ ...state, list: [...payload.companies] }),
    setCurrentCompany: (state, { payload }) => ({ ...state, current: { ...payload.company } }),
    setCurrentCompanyStatistics: (state, { payload }) => ({ ...state, statistics: { ...payload.statistics } }),
    setCompaniesMeta: (state, { payload }) => ({ ...state, meta: { ...payload.companies } }),
    resetCompaniesState: () => ({ list: [], current: { }, statistics: { }, meta: { } })
  }
});

export const { setCompaniesList, setCurrentCompany, resetCompaniesState, setCurrentCompanyStatistics, setCompaniesMeta } = companiesSlice.actions;
export default companiesSlice.reducer;
