import { TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';
import shortDateFormatter from '../../../../formatters/shortDateFormatter';

const EmploymentsListItem = ({ employeeRef, ...props }) => {
  return (
    <TableRow>
      <TableCell><Link to={`/dashboard/empleados/${employeeRef?._id}`}>{employeeRef?.identityNumber || 'S/N'}</Link></TableCell>
      <TableCell>{employeeRef?.name} {employeeRef?.firstLastName} {employeeRef?.secondLastName}</TableCell>
      <TableCell>{shortDateFormatter(props.admissionDate ?? null)}</TableCell>
      <TableCell>{!props.current ? shortDateFormatter(props.departureDate ?? null) : 'Empleo actual'}</TableCell>
      <TableCell>{props.departureReason}</TableCell>
      <TableCell>{props.firstCharge}</TableCell>
      <TableCell>{props.lastCharge}</TableCell>
      <TableCell>{props.jobArea}</TableCell>
      <TableCell>{props.supervisorName}</TableCell>
      <TableCell>{props.supervisorPhone}</TableCell>
      <TableCell>{props.supervisorEmail}</TableCell>
      <TableCell>{props.period}</TableCell>
      <TableCell>{props.pin}</TableCell>
    </TableRow>
  );
}

export default EmploymentsListItem;
