import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProspect, getProspects, updateProspect, getProspectsMetadata, getProspectsCsv } from '../api/prospectsApi';
import { showAlert } from '../slices/alertsSlice';
import { setCurrentProspect, setProspectsList, setProspectsMeta } from '../slices/prospectsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';
import { downloadFile } from '../utils/donwloadFile';

export const getProspectsList = createAsyncThunk('prospects/getProspectsList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getProspects(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setProspectsList({ prospects: data.prospects })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const downloadProspectsCsv = createAsyncThunk('prospects/downloadProspectsCsv', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getProspectsCsv(token, query)
  .then(response => response.blob())
  .then(blob => downloadFile(blob, 'prospectos.csv'))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getProspectsMeta = createAsyncThunk('prospects/getProspectsMeta', (_, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getProspectsMetadata(token)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setProspectsMeta({ prospects: data.prospects })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCurrentProspect = createAsyncThunk('prospects/getCurrentProspect', ({ id }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getProspect(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentProspect({ prospect: data.prospect })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const updateCurrentProspect = createAsyncThunk('prospects/updateCurrentProspect', ({ id, prospect }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateProspect(token, id, prospect)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentProspect({ prospect: data.prospect })))
  .then(() => dispatch(showAlert({ message: 'Prospecto actualizado', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
