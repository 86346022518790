import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import AdminDetailsForm from './AdminDetailsForm';
import AdminDetailsResetPassword from './AdminDetailsResetPassword';

import { getCurrentAdmin } from '../../../../actions/adminsActions';
import { resetAdminsState } from '../../../../slices/adminsSlice';
import { resetCompaniesState } from '../../../../slices/companiesSlice';

const styles = {
  title: {
    marginBottom: '1rem'
  },
  column: {
    paddingX: '1rem'
  }
}

const AdminDetails = () => {
  const params = useParams();

  const dispatch = useDispatch();

  const current = useSelector(state => state.admins.current);

  useEffect(() => {
    dispatch(getCurrentAdmin({ id: params.id }));
    
    return () => {
      dispatch(resetAdminsState());
      dispatch(resetCompaniesState());
    }
  }, []);

  return (
    <>
      <Typography variant="h5" sx={styles.title} fontWeight="bold">Administrador {current.name ?? params.id}</Typography>
      <Grid container>
        <Grid item xs={12} md={8} sx={styles.column}>
          <AdminDetailsForm />
        </Grid>
        <Grid item xs={12} md={4} sx={styles.column}>
          <AdminDetailsResetPassword />
        </Grid>
      </Grid>
    </>
  );
}

export default AdminDetails;
