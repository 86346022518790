const { REACT_APP_API_BASE_URL } = process.env;

export const getProspects = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    results: 10,
    ...query
  }).toString();
  const request = new Request(REACT_APP_API_BASE_URL + '/prospects?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getProspectsCsv = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    ...query
  }).toString();
  
  const request = new Request(REACT_APP_API_BASE_URL + '/prospects/csv?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getProspectsMetadata = token => new Promise((resolve, reject) => {
  
  const request = new Request(REACT_APP_API_BASE_URL + '/prospects/metadata', {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getProspect = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/prospects/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updateProspect = (token, id, prospect) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/prospects/' + id, {
    method: 'PUT',
    body: JSON.stringify({ prospect }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
