import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ADMIN_LEVELS } from '../../../../constants/adminsConstants';
import { Button, Card, CardContent, CardHeader, Grid, MenuItem, TextField } from '@mui/material';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';

import { updateCurrentEmployee, deleteCurrentEmployee } from '../../../../actions/employeesActions';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
}

const EmployeeDetailsForm = () => {
  const [employee, setEmployee] = useState({
    _id: '',
    identityNumber: '',
    identityNumberExpedition: '',
    name: '',
    firstLastName: '',
    secondLastName: '',
    address: '',
    city: '',
    fixedPhoneNumber: '',
    mobilePhoneNumber: '',
    birthdate: '',
    birthplace: '',
    gender: '',
    citizenship: '',
    public: true,
  });
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const current = useSelector(state => state.employees.current);
  const data = useSelector(state => state.auth.data);
    
  useEffect(() => setEmployee({
    ...employee,
    ...current,
    birthdate: current?.birthdate?.slice(0, 10) ?? '',
    identityNumberExpedition: current?.identityNumberExpedition?.slice(0, 10) ?? ''
  }), [current]);

  const handleChange = e => {
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();

    const { identityNumber, identityNumberExpedition, name, firstLastName, secondLastName, address, city, fixedPhoneNumber, mobilePhoneNumber, birthdate, birthplace, gender, citizenship } = employee;

    dispatch(updateCurrentEmployee({
      id: employee._id,
      employee: { identityNumber, identityNumberExpedition, name, firstLastName, secondLastName, address, city, fixedPhoneNumber, mobilePhoneNumber, birthdate, birthplace, gender, citizenship, public: employee.public }
    }));
  }

  const handleOpenDelete = () => setOpen(true);
  const handleCloseDelete = () => setOpen(false);

  const openDelete = e => {
    e.preventDefault();
    handleOpenDelete();
  }

  const handleDelete = () => {
    handleCloseDelete();
    dispatch(deleteCurrentEmployee({
      id: employee._id,
      callback: () => navigate('/dashboard/empleados', { replace: true })
    }));
  }

  return (
    <>
      <Card sx={styles.card}>
        <CardHeader title="Datos del empleado" />
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={4} sx={styles.inputWrapper}>
              <TextField label="Nombre" name="name" value={employee.name} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={4} sx={styles.inputWrapper}>
              <TextField label="Primer apellido" name="firstLastName" value={employee.firstLastName} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={4} sx={styles.inputWrapper}>
              <TextField label="Segundo apellido" name="secondLastName" value={employee.secondLastName} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={6} sx={styles.inputWrapper}>
              <TextField label="Número de identidad" name="identityNumber" value={employee.identityNumber} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={6} sx={styles.inputWrapper}>
              <TextField label="Fecha de expedición de número de identidad" type="date" InputLabelProps={{ shrink: true }} name="identityNumberExpedition" value={employee.identityNumberExpedition} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={12} sx={styles.inputWrapper}>
              <TextField label="Dirección" name="address" value={employee.address} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={6} sx={styles.inputWrapper}>
              <TextField label="Teléfono fijo" name="fixedPhoneNumber" value={employee.fixedPhoneNumber} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={6} sx={styles.inputWrapper}>
              <TextField label="Teléfono móvil" name="mobilePhoneNumber" value={employee.mobilePhoneNumber} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={6} sx={styles.inputWrapper}>
              <TextField label="Fecha de nacimiento" type="date" InputLabelProps={{ shrink: true }} name="birthdate" value={employee.birthdate} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={6} sx={styles.inputWrapper}>
              <TextField label="Lugar de nacimiento" name="birthplace" value={employee.birthplace} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={4} sx={styles.inputWrapper}>
              <TextField label="Ciudadanía" name="citizenship" value={employee.citizenship} onChange={handleChange} />
            </Grid>
            <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField select label="Género" name="gender" value={employee.gender} onChange={handleChange}>
                <MenuItem value="Masculino">Masculino</MenuItem>
                <MenuItem value="Femenino">Femenino</MenuItem>
                <MenuItem value="Otro">Otro</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField select label="Aceptó tratamiento de datos" name="public" value={employee.public} onChange={handleChange}>
                <MenuItem value={true}>Sí</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-around' }}>
              <Button color="primary" onClick={handleSubmit}>Guardar</Button>
              {data?.level === ADMIN_LEVELS.SUPERADMIN ? <Button color="error" onClick={openDelete}>Borrar</Button> : null}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ConfirmationDialog open={open} onClose={handleCloseDelete} title="Borrar empleado" message={`¿Desea borrar al empleado ${employee.name}?`} onConfirm={handleDelete} />
    </>
  );
}

export default EmployeeDetailsForm;

