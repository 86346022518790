const { REACT_APP_API_BASE_URL } = process.env;

export const loginAdmin = (email, password) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/admins/login', {
    method: 'POST',
    body: JSON.stringify({ data: { email, password } }),
    headers: {
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const logoutAdmin = token => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/admins/logout', {
    method: 'POST',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getMeAdmin = token => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/admins/me', {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
