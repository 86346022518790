import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardHeader, CardContent, Table, TableBody, TableRow, TableCell, Avatar, Divider, Button, Typography } from '@mui/material';
import EmployeeDetailsImageCard from './EmployeeDetailsImageCard';
import { Box } from '@mui/system';
import dateFormatter from '../../../../formatters/dateFormatter';
import EmployeeDetailsChanges from './EmployeeDetailsChanges';
import EmployeeDetailsNegatives from './EmployeeDetailsNegatives';
import EmployeeDetailsNoticeDialog from './EmployeeDetailsNoticeDialog';

const { REACT_APP_STORAGE_URL } = process.env;

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  media: {
    height: '25vh',
    cursor: 'pointer'
  },
  avatar: {
    width: '80px',
    height: '80px',
    cursor: 'pointer'
  },
  header: {
    color: '#000',
    backgroundColor: '#FFF',
    textAlign: 'start'
  },
  content: {
    paddingTop: '0'
  },
  actions: {
    mt: '1rem',
    display: 'flex',
    justifyContent: 'space-around'
  }
}

const EmployeeDetailsCard = () => {
  const [openPhoto, setOpenPhoto] = useState(false);
  const [openNotice, setOpenNotice] = useState(false);
  const [openChanges, setOpenChanges] = useState(false);
  const [openNegatives, setOpenNegatives] = useState(false);
  const [timestamp, setTimestamp] = useState(new Date().toISOString());

  const employee = useSelector(state => state.employees.current);
    
  useEffect(() => {
    setTimestamp(new Date().toISOString());
  }, [employee]);

  const handleOpenPhoto = () => setOpenPhoto(true);
  const handleClosePhoto = () => setOpenPhoto(false);

  const handleOpenNotice = () => setOpenNotice(true);
  const handleCloseNotice = () => setOpenNotice(false);

  const handleOpenChanges = () => setOpenChanges(true);
  const handleCloseChanges = () => setOpenChanges(false);

  const handleOpenNegatives = () => setOpenNegatives(true);
  const handleCloseNegatives = () => setOpenNegatives(false);

  const photoUrl = employee.photoUrl?.length > 0 ? `${REACT_APP_STORAGE_URL}${employee.photoUrl[0]}?timestamp=${timestamp}` : null;

  const photos = employee.photoUrl?.map?.(image => `${REACT_APP_STORAGE_URL}${image}`) ?? [];

  return (
    <>
      <Card sx={styles.card}>
        <CardHeader sx={styles.header}
          avatar={<Avatar crossOrigin="" sx={styles.avatar} alt="foto" src={photoUrl} onClick={handleOpenPhoto} />}
          title={`${employee.name} ${employee.firstLastName} ${employee.secondLastName}`}
          subheader={employee.identityNumber}
        />
        <CardContent sx={styles.content}>
          <Typography sx={{ mb: '1rem' }} variant="h6">Datos de contacto</Typography>
          <Divider />
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Dirección</TableCell>
                <TableCell>{employee.address ?? 'Dirección'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ciudad</TableCell>
                <TableCell>{employee.city ?? 'Ciudad'}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Teléfono fijo</TableCell>
                <TableCell>{employee.fixedPhoneNumber ?? ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Teléfono móvil</TableCell>
                <TableCell>{employee.mobilePhoneNumber ?? ''}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Creado en</TableCell>
                <TableCell>{dateFormatter(employee.createdAt ?? null ?? null)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Última actualización</TableCell>
                <TableCell>{dateFormatter(employee.updatedAt ?? null ?? null)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tratamiento de datos</TableCell>
                <TableCell><Button variant="text" onClick={handleOpenNotice}>Autorización</Button></TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box sx={styles.actions}>
            <Button variant="text" onClick={handleOpenNegatives}>Eventos adversos</Button>
            <Button variant="text" onClick={handleOpenChanges}>Cambios</Button>
          </Box>
        </CardContent>
      </Card>
      <EmployeeDetailsImageCard id={employee?._id} photos={photos} open={openPhoto} onClose={handleClosePhoto} />
      <EmployeeDetailsNoticeDialog id={employee?._id} notices={employee.noticeUrl ?? []} open={openNotice} onClose={handleCloseNotice} />
      <EmployeeDetailsChanges open={openChanges} onClose={handleCloseChanges} name={employee.name} />
      <EmployeeDetailsNegatives id={employee?._id} open={openNegatives} onClose={handleCloseNegatives} name={employee.name} />
    </>
  );
}

export default EmployeeDetailsCard;
