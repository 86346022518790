import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Grid, MenuItem, TextField, Typography } from '@mui/material';

import { updateCurrentCompany } from '../../../../actions/companiesActions';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
  contact: {
    py: '1rem'
  }
}

const CompanyDetailsForm = () => {
  const [company, setCompany] = useState({
    _id: '',
    name: '',
    address: '',
    city: '',
    phoneNumber: '',
    email: '',
    taxType: '',
    nit: '',
    internal: false,
    contactName: '',
    contactPhoneNumber: '',
    contactEmail: '',
  });

  const dispatch = useDispatch();
  const current = useSelector(state => state.companies.current);
    
  useEffect(() => setCompany({ ...company, ...current }), [current]);

  const handleChange = e => {
    setCompany({ ...company, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();

    const { name, address, city, phoneNumber, email, taxType, nit, internal, contactName, contactPhoneNumber, contactEmail } = company;

    dispatch(updateCurrentCompany({
      id: company._id,
      company: { name, address, city, phoneNumber, email, taxType, nit, internal, contactName, contactPhoneNumber, contactEmail }
    }));
  }

  return (
    <Card sx={styles.card}>
      <CardHeader title="Datos de la empresa" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Nombre" name="name" value={company.name} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Ciudad" name="city" value={company.city} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={12} sx={styles.inputWrapper}>
            <TextField label="Dirección" name="address" value={company.address} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Correo electrónico" name="email" value={company.email} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Teléfono móvil" name="phoneNumber" value={company.phoneNumber} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="NIT" name="nit" value={company.nit} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField select label="Tipo de contribuyente" name="taxType" value={company.taxType} onChange={handleChange}>
              <MenuItem value="Persona Natural">Persona Natural</MenuItem>
              <MenuItem value="Persona Jurídica">Persona Jurídica</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField select label="Visibilidad" name="internal" value={company.internal} onChange={handleChange}>
              <MenuItem value={false}>Pública</MenuItem>
              <MenuItem value={true}>Privada</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} sx={styles.contact}>
            <Typography variant="h6">Contacto</Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Nombre de contacto" name="contactName" value={company.contactName} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Correo de contacto" name="contactEmail" value={company.contactEmail} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={4} sx={styles.inputWrapper}>
            <TextField label="Teléfono de contacto" name="contactPhoneNumber" value={company.contactPhoneNumber} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button color="primary" onClick={handleSubmit}>Guardar</Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default CompanyDetailsForm;
