const { REACT_APP_API_BASE_URL } = process.env;

export const getEmployees = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    results: 10,
    ...query
  }).toString();
  
  const request = new Request(REACT_APP_API_BASE_URL + '/employees?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getEmployeesCsv = (token, query) => new Promise((resolve, reject) => {
  const queryParams = new URLSearchParams({
    ...query
  }).toString();
  
  const request = new Request(REACT_APP_API_BASE_URL + '/employees/csv?' + queryParams, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getEmployeesMetadata = token => new Promise((resolve, reject) => {
  
  const request = new Request(REACT_APP_API_BASE_URL + '/employees/metadata', {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const getEmployee = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/employees/' + id, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const createEmployee = (token, employee) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/employees', {
    method: 'POST',
    body: JSON.stringify({ employee }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const updateEmployee = (token, id, employee) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/employees/' + id, {
    method: 'PUT',
    body: JSON.stringify({ employee }),
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const uploadEmployeePhoto = (token, id, photo) => new Promise((resolve, reject) => {
  const data = new FormData();
  data.append('photo', photo);

  const request = new Request(`${REACT_APP_API_BASE_URL}/employees/${id}/photo`, {
    method: 'PUT',
    body: data,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const uploadEmployeeNotice = (token, id, notice) => new Promise((resolve, reject) => {
  const data = new FormData();
  data.append('notice', notice);

  const request = new Request(`${REACT_APP_API_BASE_URL}/employees/${id}/notice`, {
    method: 'PUT',
    body: data,
    headers: {
      'Authorization': 'Bearer ' + token
    }
  });

  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});

export const deleteEmployee = (token, id) => new Promise((resolve, reject) => {
  const request = new Request(REACT_APP_API_BASE_URL + '/employees/' + id, {
    method: 'DELETE',
    headers: {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    }
  });
  fetch(request)
  .then(response => resolve(response))
  .catch(err => reject(err))
});
