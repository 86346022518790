import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import { Menu } from '@mui/icons-material';
import AppDrawer from '../../components/AppDrawer';
import AppNotifications from '../../components/AppNotifications';

import { getMe } from '../../actions/authActions';

const { REACT_APP_TITLE } = process.env;

const Dashboard = () => {
  const [open, setOpen] = useState(false);

  const openDrawer = () => setOpen(true);

  const closeDrawer = () => setOpen(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMe());

    let idleTime = 0;
    setInterval(() => {
      idleTime ++;
      if(idleTime >= 15) {
        window.location.reload();
      }
    }, 60000);

    document.addEventListener('mousemove', () => idleTime = 0);
    document.addEventListener('keypress', () => idleTime = 0);
  }, []);

  return (
    <>
      <AppBar position="static" sx={{ padding: '0.5rem' }}>
        <Toolbar variant="dense" sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton edge="start" color="inherit" sx={{ mr: 1.5 }} onClick={openDrawer}>
              <Menu />
            </IconButton>
            <Typography variant="h5" color="inherit" component="div">
              {REACT_APP_TITLE}
            </Typography>
          </Box>
          <AppNotifications />
        </Toolbar>
      </AppBar>
      <AppDrawer open={open} onClose={closeDrawer} />
      <Box component="main" sx={{ padding: '1rem', display: 'flex', flexDirection: 'column' }}>
        <Outlet />
      </Box>
    </>
  );
}

export default Dashboard;
