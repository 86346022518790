import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Send, UploadFile } from '@mui/icons-material';
import { Box, Container, IconButton, TextField } from '@mui/material';

import { createNewTicketMessage, getCurrentTicket } from '../../../../actions/ticketsActions';
import TicketDetailsUploadFile from './TicketDetailsUploadFile';

const styles = {
  box: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    flex: 1,
    mr: '1rem'
  },
}

const TicketDetailsNewMessage = props => {
  const [message, setMessage] = useState('');
  const [openFile, setOpenFile] = useState(false);

  const dispatch = useDispatch();

  const params = useParams();

  const handleChange = e => setMessage(e.target.value);

  const handleSubmit = e => {
    e?.preventDefault();
    dispatch(createNewTicketMessage({ id: params.id, message, callback: () => {
      dispatch(getCurrentTicket({ id: params.id, callback: props.scrollCallback }));
      setMessage('');
    } }));
  }

  const handleOpenFile = () => setOpenFile(true);
  const handleCloseFile = () => setOpenFile(false);

  return (
    <Container>
      <Box component="form" sx={styles.box} onSubmit={handleSubmit}>
        <TextField multiline rows={5} sx={styles.input} label="Añadir comentario" value={message} onChange={handleChange} />
        <Box>
          <IconButton type="submit" disabled={message.length < 1}><Send /></IconButton>
        </Box>
        <Box>
          <IconButton onClick={handleOpenFile}><UploadFile /></IconButton>
        </Box>
      </Box>
      <TicketDetailsUploadFile open={openFile} onClose={handleCloseFile} scrollCallback={props.scrollCallback} />
    </Container>
  );
}

export default TicketDetailsNewMessage;
