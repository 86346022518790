import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import Chart from 'chart.js/auto';
import { Box } from '@mui/material';

const MetricsEmployeesChart = () => {
  const employees = useSelector(state => state.metrics.employees);
  const chartRef = useRef();

  const config = useMemo(() => {
    const { byGender, byVisibility, byCitizenship } = employees;

    const total = byVisibility.reduce((prev, curr) => prev + curr.count, 0);

    return {
      type: 'bar',
      data: {
        labels: ['Total', 'Por visibilidad', 'Por género', 'Por ciudadanía'],
        datasets: [
          {
            label: 'Total',
            data: [total],
          },
          ...byVisibility.map((data, i) => ({
            label: data._id ? 'Autoriza' : 'No autoriza',
            data: [null, data.count],
          })),
          ...byGender.map((data, i) => ({
            label: data._id,
            data: [null, null, data.count],
          })),
          ...byCitizenship.map(data => ({
            label: data._id,
            data: [null, null, null, data.count],
          })),
        ]
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Segmentación de los empleados'
          },
          legend: {
            display: false
          }
        },
        responsive: true,
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true,
            beginAtZero: true
          }
        }
      }
    };
  }, [employees]);

  useEffect(() => {
    const chart = new Chart(chartRef.current, config);

    return () => chart.destroy();
  }, [config, chartRef]);

  return <Box sx={{ marginY: '1rem' }}><canvas ref={chartRef} /></Box>
}

export default MetricsEmployeesChart;
