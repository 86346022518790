import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardContent, CardHeader, Grid, MenuItem, TextField } from '@mui/material';

import { updateCurrentAdmin } from '../../../../actions/adminsActions';

const styles = {
  card: {
    marginBottom: '1.5rem'
  },
  inputWrapper: {
    paddingX: '0.5rem'
  },
  title: {
    py: '1rem'
  },
  companyWrapper: {
    display: 'flex',
    alignItems: 'center'
  }
}

const AdminDetailsForm = () => {
  const [admin, setAdmin] = useState({
    _id: '',
    name: '',
    email: '',
    status: '',
    level: ''
  });

  const dispatch = useDispatch();
  const current = useSelector(state => state.admins.current);
    
  useEffect(() => setAdmin({ ...admin, ...current }), [current]);

  const handleChange = e => {
    setAdmin({ ...admin, [e.target.name]: e.target.value });
  }

  const handleSubmit = e => {
    e.preventDefault();

    const { name, status, level } = admin;

    dispatch(updateCurrentAdmin({
      id: admin._id,
      admin: { name, status, level }
    }));
  }

  return (
    <Card sx={styles.card}>
      <CardHeader title="Datos del administrador" />
      <CardContent>
        <Grid container>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Nombre" name="name" value={admin.name} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField label="Correo electrónico" name="email" disabled value={admin.email} onChange={handleChange} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField select label="Nivel de permisos" name="level" value={admin.level} onChange={handleChange}>
              <MenuItem value="Administrador">Administrador</MenuItem>
              <MenuItem value="Superadmin">Superadmin</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} md={6} sx={styles.inputWrapper}>
            <TextField select label="Estado" name="status" value={admin.status} onChange={handleChange}>
              <MenuItem value="Activo">Activo</MenuItem>
              <MenuItem value="Desactivado">Desactivado</MenuItem>
              <MenuItem value="Bloqueado">Bloqueado</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button color="primary" onClick={handleSubmit}>Guardar</Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default AdminDetailsForm;
