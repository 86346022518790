import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Container, Typography } from '@mui/material';
import MetricsPurchasesChart from './MetricsPurchasesChart';
import MetricsEmployeesChart from './MetricsEmployeesChart';

import { resetMetricsState } from '../../../slices/metricsSlice';
import { getMetricsEmployeesData, getMetricsPurchasesData } from '../../../actions/metricsActions';

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem'
  }
}

const Metrics = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMetricsPurchasesData({}));
    dispatch(getMetricsEmployeesData({}));
    return () => dispatch(resetMetricsState());
  }, []);

  return (
    <>
      <Helmet>
        <title>Cristopher | Métricas</title>
      </Helmet>
      <Box sx={styles.title}>
        <Typography variant="h5" fontWeight="bold">Métricas</Typography>
      </Box>
      <Container>
        <MetricsPurchasesChart />
        <MetricsEmployeesChart />
      </Container>
    </>
  );
}

export default Metrics;
