import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import CompanyDetailsEmploymentsListItem from './CompanyDetailsEmploymentsListItem';

const EmploymentsListTable = props => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>Empleado</TableCell>
            <TableCell>Nombre Empleado</TableCell>
            <TableCell>Fecha de ingreso</TableCell>
            <TableCell>Fecha de salida</TableCell>
            <TableCell>Razón de salida</TableCell>
            <TableCell>Cargo inicial</TableCell>
            <TableCell>Cargo final</TableCell>
            <TableCell>Área laboral</TableCell>
            <TableCell>Nombre Supervisor</TableCell>
            <TableCell>Teléfono Supervisor</TableCell>
            <TableCell>Correo Supervisor</TableCell>
            <TableCell>Periodo</TableCell>
            <TableCell>Pin</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.employments?.map(employment => <CompanyDetailsEmploymentsListItem key={employment._id} {...employment} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EmploymentsListTable;
