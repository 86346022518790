import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  current: { },
  list: []
}

const envsSlice = createSlice({
  name: 'envs',
  initialState,
  reducers: {
    setEnvsList: (state, { payload }) => ({ ...state, list: [...payload.envs] }),
    setCurrentEnv: (state, { payload }) => ({ ...state, current: { ...payload.env } }),
    resetEnvsState: () => ({ list: [], current: { } })
  }
});

export const { setEnvsList, setCurrentEnv, resetEnvsState } = envsSlice.actions;
export default envsSlice.reducer;
