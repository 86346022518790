import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import EmployeeDetailsEmploymentsListTable from './EmployeeDetailsEmploymentsListTable';
import AppPagination from '../../../../components/AppPagination';

import { resetEmploymentsState } from '../../../../slices/employmentsSlice';
import { downloadEmploymentsCsv, getEmploymentsList } from '../../../../actions/employmentsActions';

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem'
  }
}

const EmployeeDetailsEmployments = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const employments = useSelector(state => state.employments.list);
  
  const [pagination, setPagination] = useState({ page: 1 });

  useEffect(() => {
    setPagination({ page: 1 });
    return () => dispatch(resetEmploymentsState());
  }, []);

  useEffect(() => {
    dispatch(getEmploymentsList({ query: { employee: params.id } }));
  }, [pagination]);

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () => setPagination({ page: Math.max(pagination.page - 1, 1) });

  const handleDownload = e => {
    e.preventDefault();

    dispatch(downloadEmploymentsCsv({ query: { employee: params.id } }));
  }

  return (
    <>
      <Box sx={styles.title}>
        <Typography variant="h5" fontWeight="bold" sx={{ marginBottom: '1rem' }}>Empleos</Typography>
        <Button onClick={handleDownload}>Descargar</Button>
      </Box>
      <EmployeeDetailsEmploymentsListTable employments={employments} />
      <AppPagination page={pagination.page} prev={prevPage} next={nextPage} />
    </>
  );
}

export default EmployeeDetailsEmployments;
