import { TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';
import dateFormatter from '../../../formatters/dateFormatter';

const AdminsListItem = props => {
  return (
    <TableRow>
      <TableCell><Link to={props._id}>{props.name}</Link></TableCell>
      <TableCell>{props.email}</TableCell>
      <TableCell>{props.level}</TableCell>
      <TableCell>{dateFormatter(props.createdAt ?? null)}</TableCell>
    </TableRow>
  );
}

export default AdminsListItem;
