import { createAsyncThunk } from '@reduxjs/toolkit';
import { createEmployee, deleteEmployee, getEmployee, getEmployees, getEmployeesCsv, getEmployeesMetadata, updateEmployee, uploadEmployeeNotice, uploadEmployeePhoto } from '../api/employeesApi';
import { showAlert } from '../slices/alertsSlice';
import { setCurrentEmployee, setEmployeesList, setEmployeesMeta, setTentativeEmployee } from '../slices/employeesSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';
import { downloadFile } from '../utils/donwloadFile';

export const getEmployeesList = createAsyncThunk('employees/getEmployeesList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getEmployees(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setEmployeesList({ employees: data.employees })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const downloadEmployeesCsv = createAsyncThunk('employees/downloadEmployeesCsv', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getEmployeesCsv(token, query)
  .then(response => response.blob())
  .then(blob => downloadFile(blob, 'empleados.csv'))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getEmployeesMeta = createAsyncThunk('employees/getEmployeesMeta', (_, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getEmployeesMetadata(token)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setEmployeesMeta({ employees: data.employees })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCurrentEmployee = createAsyncThunk('employees/getCurrentEmployee', ({ id }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getEmployee(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentEmployee({ employee: data.employee })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const createNewEmployee = createAsyncThunk('employees/createNewEmployee', ({ employee, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createEmployee(token, employee)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.employee))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const updateCurrentEmployee = createAsyncThunk('employees/updateCurrentEmployee', ({ id, employee }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updateEmployee(token, id, employee)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentEmployee({ employee: data.employee })))
  .then(() => dispatch(showAlert({ message: 'Empleado actualizado', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const uploadCurrentEmployeePhoto = createAsyncThunk('employees/uploadCurrentEmployeePhoto', ({ id, photo }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  uploadEmployeePhoto(token, id, photo)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => setTimeout(() => dispatch(setCurrentEmployee({ employee: data.employee })), 1000))
  .then(() => dispatch(showAlert({ message: 'Empleado actualizado', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const uploadCurrentEmployeeNotice = createAsyncThunk('employees/uploadCurrentEmployeeNotice', ({ id, notice }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  uploadEmployeeNotice(token, id, notice)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentEmployee({ employee: data.employee })))
  .then(() => dispatch(showAlert({ message: 'Empleado actualizado', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const findTentativeEmployee = createAsyncThunk('employees/findTentativeEmployee', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getEmployees(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => data.employees)
  .then(employees => employees?.length > 0 ? dispatch(setTentativeEmployee({ employee: employees[0] })) : null)
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const deleteCurrentEmployee = createAsyncThunk('employees/deleteCurrentEmployee', ({ id, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  deleteEmployee(token, id)
  .then(response => response.status === 200 ? true : Promise.reject(response))
  .then(() => dispatch(showAlert({ message: 'Empleado borrado', type: 'success' })))
  .then(() => callback())
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
