import { createAsyncThunk } from '@reduxjs/toolkit';
import { createPayment, getPayment, getPayments, updatePayment } from '../api/paymentsApi';
import { showAlert } from '../slices/alertsSlice';
import { setCurrentPayment, setPaymentsList } from '../slices/paymentsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const getPaymentsList = createAsyncThunk('payments/getPaymentsList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getPayments(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setPaymentsList({ payments: data.payments })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCurrentPayment = createAsyncThunk('payments/getCurrentPayment', ({ id }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getPayment(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentPayment({ payment: data.payment })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const createNewPayment = createAsyncThunk('payments/createNewPayment', ({ payment, callback }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  createPayment(token, payment)
  .then(response => response.status === 201 ? response.json() : Promise.reject(response))
  .then(data => callback(data.payment))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const updateCurrentPayment = createAsyncThunk('payments/updateCurrentPayment', ({ id, payment }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());
  
  updatePayment(token, id, payment)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentPayment({ payment: data.payment })))
  .then(() => dispatch(showAlert({ message: 'Paquete actualizado', type: 'success' })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
