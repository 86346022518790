import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Button, Typography } from '@mui/material';
import EmployeesListTable from './EmployeesListTable';
import EmployeesFilter from './EmployeesFilter';
import AppPagination from '../../../components/AppPagination';

import { resetEmployeesState } from '../../../slices/employeesSlice';
import { downloadEmployeesCsv, getEmployeesList, getEmployeesMeta } from '../../../actions/employeesActions';

const defaultFilter = {
  identityNumber: '',
  name: '',
  firstLastName: '',
  secondLastName: '',
  email: '',
  citizenship: '',
  gender: '',
  public: '',
};

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem'
  }
}

const Employees = () => {
  const dispatch = useDispatch();
  const employees = useSelector(state => state.employees.list);
  const meta = useSelector(state => state.employees.meta);

  const [filter, setFilter] = useState({ ...defaultFilter });
  const [pagination, setPagination] = useState({ page: 1 });

  useEffect(() => {
    dispatch(getEmployeesMeta());
    setPagination({ page: 1 });
    return () => dispatch(resetEmployeesState());
  }, []);

  useEffect(() => {
    const f = { page: pagination.page };

    filter.identityNumber.length > 0 ? f.identityNumber = filter.identityNumber : null;
    filter.name.length > 0 ? f.name = filter.name : null;
    filter.firstLastName.length > 0 ? f.firstLastName = filter.firstLastName : null;
    filter.secondLastName.length > 0 ? f.secondLastName = filter.secondLastName : null;
    filter.citizenship.length > 0 ? f.citizenship = filter.citizenship : null;
    filter.gender.length > 0 ? f.gender = filter.gender : null;
    filter.public === '' ? null : f.public = filter.public;

    dispatch(getEmployeesList({ query: f }));
  }, [pagination]);

  const handleChange = e => setFilter({ ...filter, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    setPagination({ page: 1 });
  }

  const handleDownload = e => {
    e.preventDefault();

    const f = { page: pagination.page };

    filter.identityNumber.length > 0 ? f.identityNumber = filter.identityNumber : null;
    filter.name.length > 0 ? f.name = filter.name : null;
    filter.firstLastName.length > 0 ? f.firstLastName = filter.firstLastName : null;
    filter.secondLastName.length > 0 ? f.secondLastName = filter.secondLastName : null;
    filter.citizenship.length > 0 ? f.citizenship = filter.citizenship : null;
    filter.gender.length > 0 ? f.gender = filter.gender : null;
    filter.public === '' ? null : f.public = filter.public;
    
    dispatch(downloadEmployeesCsv({ query: f }));
  }

  const onClear = () => setFilter({ ...defaultFilter });

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () => setPagination({ page: Math.max(pagination.page - 1, 1) });

  return (
    <>
      <Helmet>
        <title>Cristopher | Empleados</title>
      </Helmet>
      <Box sx={styles.title}>
        <Typography variant="h5" fontWeight="bold">Empleados (total: {meta.total ?? 0})</Typography>
        <Box>
          <Button component={Link} to="cargar">Cargar</Button>
          <Button onClick={handleDownload} sx={{ ml: '1rem' }}>Descargar</Button>
        </Box>
      </Box>
      <EmployeesFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <EmployeesListTable employees={employees} />
      <AppPagination page={pagination.page} prev={prevPage} next={nextPage} />
    </>
  );
}

export default Employees;
