import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import BundlesListItem from './BundlesListItem';

const BundlesListTable = props => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>Nombre</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Descripción</TableCell>
            <TableCell>Consultas</TableCell>
            <TableCell>Precio</TableCell>
            <TableCell>Creado en</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.bundles?.map(bundle => <BundlesListItem key={bundle._id} {...bundle} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BundlesListTable;
