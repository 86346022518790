import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import CompanyDetailsEmploymentsListTable from './CompanyDetailsEmploymentsListTable';
import AppPagination from '../../../../components/AppPagination';

import { resetEmploymentsState } from '../../../../slices/employmentsSlice';
import { downloadEmploymentsCsv, getEmploymentsList } from '../../../../actions/employmentsActions';

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem'
  }
}

const CompanyDetailsEmployments = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const employments = useSelector(state => state.employments.list);
  const statistics = useSelector(state => state.companies.statistics);

  const [pagination, setPagination] = useState({ page: 1 });

  useEffect(() => {
    setPagination({ page: 1 });
    return () => dispatch(resetEmploymentsState());
  }, []);

  useEffect(() => {
    dispatch(getEmploymentsList({ query: { company: params.id, page: pagination.page } }));
  }, [pagination]);

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () => setPagination({ page: Math.max(pagination.page - 1, 1) });

  const handleDownload = e => {
    e.preventDefault();

    dispatch(downloadEmploymentsCsv({ query: { company: params.id, page: pagination.page } }));
  }

  return (
    <>
      <Box sx={styles.title}>
        <Typography variant="h5" fontWeight="bold">Empleados</Typography>
        <Box>
          <Button component={Link} to={`/dashboard/empleados/cargar?empresa=${params.id}`}>Cargar</Button>
          <Button onClick={handleDownload} sx={{ ml: '1rem' }}>Descargar</Button>
        </Box>
      </Box>
      <CompanyDetailsEmploymentsListTable employments={employments} />
      <AppPagination page={pagination.page} max={statistics.total / 10} prev={prevPage} next={nextPage} />
    </>
  );
}

export default CompanyDetailsEmployments;
