import { Paper, TableContainer, Table, TableBody, TableHead, TableRow, TableCell } from '@mui/material';
import QueriesListItem from './QueriesListItem';

const QueriesListTable = props => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead color="primary">
          <TableRow>
            <TableCell>Consulta</TableCell>
            <TableCell>Usuario</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Creado en</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.queries?.map(query => <QueriesListItem key={query._id} {...query} />)}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default QueriesListTable;

