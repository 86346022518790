import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, Button, DialogActions, Typography } from '@mui/material';
import { Splide } from '@splidejs/react-splide';
import EmployeeDetailsReportsSlide from './EmployeeDetailsReportsSlide';

import { getReportsList } from '../../../../actions/reportsActions';
import EmployeeDetailsCreateReport from './EmployeeDetailsCreateReport';

const styles = {
  container: {
    display: 'block'
  },
  content: {
    paddingY: 0
  },
  actions: {
    paddingTop: 0,
    paddingRight: '1rem'
  },
}

const EmployeeDetailsRepotsDialog = props => {
  const dispatch = useDispatch();
  const reports = useSelector(state => state.reports.list);

  const [openNew, setOpenNew] = useState(false);

  const handleOpenNew = () => setOpenNew(true);
  const handleCloseNew = () => setOpenNew(false);

  const handleClose = e => {
    e.preventDefault();
    props.onClose();
  }

  useEffect(() => {
    if(props.open) {
      dispatch(getReportsList({ employmentId: props.employmentId }))
    }
  }, [props.open]);

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
      >
        <DialogTitle>Eventos conductuales</DialogTitle>
        <DialogContent sx={styles.content}>
          {
            reports.length > 0
            ? <Splide>{reports.map(report => <EmployeeDetailsReportsSlide key={report._id} {...report} employmentId={props.employmentId} />)}</Splide>
            : <Typography>No hay informes para mostrar</Typography>
          }
        </DialogContent>
        <DialogActions sx={styles.actions}>
          <Button variant="text" onClick={handleOpenNew}>Nuevo</Button>
          <Button variant="text" onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
      <EmployeeDetailsCreateReport open={openNew} onClose={handleCloseNew} employmentId={props.employmentId} />
    </>
  );
}

export default EmployeeDetailsRepotsDialog;
