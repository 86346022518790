import { createAsyncThunk } from '@reduxjs/toolkit';
import { getQuery, getQueries } from '../api/queriesApi';
import { setCurrentQuery, setQueriesList } from '../slices/queriesSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const getQueriesList = createAsyncThunk('queries/getQueriesList', ({ query }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getQueries(token, query)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setQueriesList({ queries: data.queries })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getCurrentQuery = createAsyncThunk('queries/getCurrentQuery', ({ id }, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getQuery(token, id)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setCurrentQuery({ query: data.query })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
