import { TableRow, TableCell } from '@mui/material';
import { Link } from 'react-router-dom';
import dateFormatter from '../../../formatters/dateFormatter';

const EmployeesListItem = props => {
  return (
    <TableRow>
      <TableCell><Link to={props._id}>{props.identityNumber}</Link></TableCell>
      <TableCell>{props.name} {props.firstLastName} {props.secondLastName}</TableCell>
      <TableCell>{props.city}</TableCell>
      <TableCell>{props.fixedPhoneNumber}</TableCell>
      <TableCell>{props.mobilePhoneNumber}</TableCell>
      <TableCell>{dateFormatter(props.createdAt ?? null)}</TableCell>
    </TableRow>
  );
}

export default EmployeesListItem;
