import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Box, Typography } from '@mui/material';
import TicketsListTable from './TicketsListTable';
import TicketsFilter from './TicketsFilter';
import AppPagination from '../../../components/AppPagination';

import { resetTicketsState } from '../../../slices/ticketsSlice';
import { getTicketsList } from '../../../actions/ticketsActions';

const defaultFilter = {
  subject: '',
};

const styles = {
  title: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '0.5rem'
  }
}

const Tickets = () => {
  const dispatch = useDispatch();
  const tickets = useSelector(state => state.tickets.list);
  
  const [filter, setFilter] = useState({ ...defaultFilter });
  const [pagination, setPagination] = useState({ page: 1 });

  useEffect(() => {
    setPagination({ page: 1 });
    return () => dispatch(resetTicketsState());
  }, []);

  useEffect(() => {
    const f = { page: pagination.page };

    filter.subject.length > 0 ? f.subject = filter.subject : null;

    dispatch(getTicketsList({ query: f }));
  }, [pagination]);

  const handleChange = e => setFilter({ ...filter, [e.target.name]: e.target.value });

  const onSubmit = e => {
    e.preventDefault();
    setPagination({ page: 1 });
  }

  const onClear = () => setFilter({ ...defaultFilter });

  const nextPage = () => setPagination({ page: pagination.page + 1 });
  const prevPage = () => setPagination({ page: Math.max(pagination.page - 1, 1) });

  return (
    <>
      <Helmet>
        <title>Cristopher | Tickets</title>
      </Helmet>
      <Box sx={styles.title}>
        <Typography variant="h5" fontWeight="bold">Tickets</Typography>
      </Box>
      <TicketsFilter {...filter} onChange={handleChange} onSubmit={onSubmit} onClear={onClear} />
      <TicketsListTable tickets={tickets} />
      <AppPagination page={pagination.page} prev={prevPage} next={nextPage} />
    </>
  );
}

export default Tickets;
