import { createAsyncThunk } from '@reduxjs/toolkit';
import { getMetricsEmployees, getMetricsPurchases } from '../api/metricsApi';
import { setEmployees, setPurchases } from '../slices/metricsSlice';
import { handleError } from '../slices/errorsSlice';
import { startLoading, endLoading } from '../slices/loadingSlice';

export const getMetricsPurchasesData = createAsyncThunk('metrics/getMetricsPurchasesData', (_, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getMetricsPurchases(token)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setPurchases({ ...data.metrics })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});

export const getMetricsEmployeesData = createAsyncThunk('metrics/getMetricsEmployeesData', (_, { getState, dispatch }) => {
  const { token } = getState().auth;

  dispatch(startLoading());

  getMetricsEmployees(token)
  .then(response => response.status === 200 ? response.json() : Promise.reject(response))
  .then(data => dispatch(setEmployees({ ...data.metrics })))
  .catch(err => dispatch(handleError(err)))
  .finally(() => dispatch(endLoading()));
});
