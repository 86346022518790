import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import PrivacyPolicyUpload from './PrivacyPolicyUpload';

const styles = {
  doc: {
    px: {
      md: '3rem',
    }
  }
}

const PrivacyPolicy = () => {
  const url = 'https://cristopherdata.sfo3.digitaloceanspaces.com/docs/politica%20de%20privacidad.pdf?timestamp=';
  const [timestamp, setTimestamp] = useState('');

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const callback = () => {
    setTimestamp(+ new Date());
    handleClose();
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h5" fontWeight="bold">Políticas de tratamiento de datos, Términos y Condiciones</Typography>
        <Button onClick={handleOpen}>Actualizar</Button>
      </Box>
      <Box sx={styles.doc}>
        <DocViewer documents={[{ uri: `${url}${timestamp}` }]} pluginRenderers={DocViewerRenderers} config={{ header: { disableFileName: true } }} />
      </Box>
      <PrivacyPolicyUpload open={open} onClose={handleClose} callback={callback} />
    </Box>
  );
}

export default PrivacyPolicy;
